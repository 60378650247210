import { DEFAULT_PLAY_SPEED } from "../../constant";
import { Language } from "../../i18n";
import localData from "../../localStorageKeys";
import { TextChatWindowType } from "../types";
import { Account, AccountActions, AccountTypes } from "./actions";

export type InvitedEnv = {
  textToSpeechEnabled: boolean;
  listenerMicEnabled: boolean;
  inviteeUserId?: number;
};

export type AccountState = {
  loaded: boolean;
  user: Account;
  invitedEnv?: InvitedEnv;
  redirectPath: string | null;
  acceptedInvitation: boolean;
  workspaceId?: number;
  isAppIdle: boolean | null;
};

export const accountInitialState: AccountState = {
  loaded: false,
  user: {
    id: 0,
    planDetails: {
      name: { en: "", ja: "", vi: "", ko: "" },
      maxUsersPerWorkspace: -1,
      maxFloorsPerWorkspace: -1,
      maxMapWidth: -1,
      maxMapHeight: -1,
      maxVerticalMapWidth: -1,
      maxVerticalMapHeight: -1,
      demo: false,
      upgradable: false,
      maxSpeechToTextMinutes: -1,
      maxWorkspaces: -1,
      maxFileStorage: 0,
    },
    name: "",
    fullName: "",
    displayName: "",
    projectId: null,
    voiceChannelId: null,
    email: "",
    company: null,
    size: null,
    role: null,
    phone: null,
    etherpadAuthorId: null,
    unconfirmedEmail: null,
    language: localStorage.getItem("language") ? (localStorage.getItem("language") as Language) : "en",
    authenticated: false,
    signupWithPassword: true,
    workspaceId: null,
    workspaces: [],
    projects: [],
    knowUs: null,
    slack: { connected: true, missingScopes: { userScopes: [], botScopes: [] } },
    emailTokenFlag: false,
    assignedFloor: [],
    isGuest: false,
    isListener: false,
    isMeetingRecording: false,
    skyWayAPIKey: null,
    showOriginalMessage: true,
    textTranslation: false,
    textFontSize: 18,
    playSpeed: DEFAULT_PLAY_SPEED,
    enableWebApp: false,
    enableTimeTrackerLimit: false,
    pinVoiceChannels: JSON.parse(localData.fetch("sidebar.voiceChannel.pinnedVoiceChannels")) as number[],
    pinUsers: JSON.parse(localData.fetch("sidebar.voiceChannel.pinnedUsers")) as number[],
    shortId: "",
    enableSharing: false,
    sharingPassword: null,
    enableSharingTextToSpeech: false,
    enableSharingMic: false,
    isSystemMail: false,
    isMarketingMail: false,
    textChatWindowType: TextChatWindowType.SUBTITLE,
  },
  invitedEnv: undefined,
  redirectPath: null,
  acceptedInvitation: false,
  workspaceId: undefined,
  isAppIdle: null,
};

export function accountReducer(state: AccountState = accountInitialState, action: AccountActions): AccountState {
  switch (action.type) {
    case AccountTypes.ACCEPT_INVITATION:
      return { ...state, ...action.payload };
    case AccountTypes.RELOAD_ACCOUNT:
      return { ...state, loaded: false, user: accountInitialState.user };
    case AccountTypes.SET_ACCOUNT:
      return {
        ...state,
        user: { ...state.user, ...action.payload.user },
        loaded: true,
      };
    case AccountTypes.SET_ACCOUNT_WORKSPACE:
    case AccountTypes.SET_ACCOUNT_ENABLE_WEB_APP:
    case AccountTypes.SET_ACCOUNT_COMPANY:
    case AccountTypes.UPDATE_PROFILE:
    case AccountTypes.SET_LANGUAGE:
    case AccountTypes.GOOGLE_INTEGRATION_UPDATE:
    case AccountTypes.SET_ACCOUNT_INTEGRATION_DATA:
    case AccountTypes.UPDATE_SHARING:
      return { ...state, user: { ...state.user, ...action.payload } };
    case AccountTypes.UPDATED_PASSWORD:
      return { ...state, user: { ...state.user, signupWithPassword: true } };
    case AccountTypes.UPDATE_ACCOUNT_EMAIL_TOKEN_FLAG:
      return { ...state, user: { ...state.user, emailTokenFlag: action.payload.emailTokenFlag } };
    case AccountTypes.SLACK_INTEGRATION_UPDATE:
      return {
        ...state,
        user: {
          ...state.user,
          slack: state.user.slack ? { ...state.user.slack, connected: action.payload.connectedSlack } : undefined,
        },
      };
    case AccountTypes.SLACK_MISSING_SCOPES:
      return {
        ...state,
        user: {
          ...state.user,
          slack: state.user.slack ? { ...state.user.slack, missingScopes: action.payload } : undefined,
        },
      };
    case AccountTypes.LOGOUT:
      return { ...accountInitialState };
    case AccountTypes.SET_REDIRECT_PATH:
      return { ...state, redirectPath: action.payload.redirectPath };
    case AccountTypes.CHATWORK_INTEGRATION_UPDATE:
      return { ...state, user: { ...state.user, connectedChatwork: action.payload.connectedChatwork } };
    case AccountTypes.CHATWORK_GROUP_ID_UPDATE:
      return { ...state, user: { ...state.user, chatworkGroupId: action.payload.chatworkGroupId } };
    case AccountTypes.AZURE_INTEGRATION_UPDATE:
      return { ...state, user: { ...state.user, connectedAzure: action.payload.connectedAzure } };
    case AccountTypes.UPDATE_ACCOUNT_PROJECTS:
      return { ...state, user: { ...state.user, projects: action.payload.projects } };
    case AccountTypes.UPDATE_ACCOUNT_ASSIGNED_FLOORS:
      return { ...state, user: { ...state.user, assignedFloor: action.payload.floors } };
    case AccountTypes.UPDATE_ACCOUNT_WORKSPACE_ROLE:
      return {
        ...state,
        user: {
          ...state.user,
          workspaces: state.user.workspaces?.map(w => {
            if (w.id === action.payload.workspaceId) {
              return { ...w, role: action.payload.role };
            }

            return w;
          }),
        },
      };
    case AccountTypes.UPDATE_ACCOUNT_SELECTED_PROJECT:
      return { ...state, user: { ...state.user, projectId: action.payload.projectId } };
    case AccountTypes.UPDATE_ACCOUNT_SHOW_ORIGINAL_MESSAGE:
      return { ...state, user: { ...state.user, showOriginalMessage: action.payload.showOriginalMessage } };
    case AccountTypes.UPDATE_ACCOUNT_TEXT_TRANSLATION:
      return { ...state, user: { ...state.user, textTranslation: action.payload.textTranslation } };
    case AccountTypes.UPDATE_CMS_LOGIN_AT:
      return {
        ...state,
        user: {
          ...state.user,
          workspaces: state.user.workspaces?.map(w =>
            w.id === action.payload.workspaceId ? { ...w, cmsLoginAt: action.payload.cmsLoginAt } : w,
          ),
        },
      };
    case AccountTypes.SET_IS_APP_IDLE:
      return {
        ...state,
        isAppIdle: action.payload.isAppIdle,
      };
    case AccountTypes.SET_ACCOUNT_TIME_TRACKER_LIMIT:
      return {
        ...state,
        user: {
          ...state.user,
          enableTimeTrackerLimit: action.payload.timeTrackerData?.enableTimeTrackerLimit,
          dailyLimit: action.payload.timeTrackerData?.dailyLimit,
          weeklyLimit: action.payload.timeTrackerData?.weeklyLimit,
          monthlyLimit: action.payload.timeTrackerData?.monthlyLimit,
          enableShift: action.payload.timeTrackerData?.enableShift,
          days: action.payload.timeTrackerData?.days,
          slots: action.payload.timeTrackerData?.enableShift ? action.payload.timeTrackerData?.slots : [],
          timezoneOffset: action.payload.timeTrackerData?.timezoneOffset,
          todayTime: action.payload.timeTrackerData?.todayTime,
          currentWeekTime: action.payload.timeTrackerData?.currentWeekTime,
          currentMonthTime: action.payload.timeTrackerData?.currentMonthTime,
        },
      };
    case AccountTypes.UPDATE_ACCOUNT_TIME_TRACKER_LIMIT:
      return {
        ...state,
        user: {
          ...state.user,
          enableTimeTrackerLimit: action.payload.timeTrackerData?.enableTimeTrackerLimit,
          dailyLimit: action.payload.timeTrackerData?.dailyLimit,
          weeklyLimit: action.payload.timeTrackerData?.weeklyLimit,
          monthlyLimit: action.payload.timeTrackerData?.monthlyLimit,
          enableShift: action.payload.timeTrackerData?.enableShift,
          days: action.payload.timeTrackerData?.days,
          timezoneOffset: action.payload.timeTrackerData?.timezoneOffset,
          slots: action.payload.timeTrackerData?.enableShift ? action.payload.timeTrackerData?.slots : [],
        },
      };
    case AccountTypes.UPDATE_PIN_VOICE_CHANNELS:
      return {
        ...state,
        user: {
          ...state.user,
          pinVoiceChannels: action.payload.voiceChannels,
        },
      };
    case AccountTypes.UPDATE_PIN_USERS:
      return {
        ...state,
        user: {
          ...state.user,
          pinUsers: action.payload.userIds,
        },
      };
    case AccountTypes.UPDATE_TEXT_FONT_SIZE:
      return {
        ...state,
        user: {
          ...state.user,
          textFontSize: action.payload.textFontSize,
        },
      };
    case AccountTypes.UPDATE_PLAY_SPEED:
      return {
        ...state,
        user: {
          ...state.user,
          playSpeed: action.payload.playSpeed,
        },
      };
    case AccountTypes.UPDATE_TIME_TRACKING_INITIAL_DATA:
      return { ...state, user: { ...state.user, timetrackingInitialData: action.payload } };
    case AccountTypes.SET_INVITED_ENV:
      return {
        ...state,
        invitedEnv: action.payload,
      };
    case AccountTypes.UPDATE_PLAN_CANCEL_STATUS:
      return {
        ...state,
        user: {
          ...state.user,
          workspaces: state.user.workspaces?.map(w => {
            if (w.id === action.payload.workspaceId) {
              return { ...w, willPlanBeCanceled: action.payload.willPlanBeCanceled };
            }

            return w;
          }),
        },
      };
    case AccountTypes.UPDATE_MAIL_NOTIFICATION_SETTINGS:
      return {
        ...state,
        user: {
          ...state.user,
          isMarketingMail: action.payload.isMarketingMail,
          isSystemMail: action.payload.isSystemMail,
        },
      };
    case AccountTypes.SET_TEXT_CHAT_WINDOW_TYPE:
      return {
        ...state,
        user: {
          ...state.user,
          textChatWindowType: action.payload.textChatWindowType,
        },
      };
    default:
      return state;
  }
}
