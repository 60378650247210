import React from "react";
import { FormattedMessage } from "react-intl";
import { notification } from "../../components/antd/Notification";
import { Toast } from "../../components/antd/Toast";
import { intl } from "../../i18n";
import { TRANSCRIPT_LIBRARY, generateTranscriptContentWithParams } from "../../routes";
import { destroyTextChatWindowForAudioExtraction, openTextChatWindowForAudioExtraction, showSubtitle } from "../app";
import {
  addNotificationKey,
  addNotificationOnPIP,
  removeNotificationKey,
  removeNotificationOnPIP,
  showNotificationWindow,
} from "../notificationsWindow";
import { disconnectSocket, sendMessage } from "../socket";
import { AppThunk, TextChatWindowType } from "../types";
import {
  USER_EVENT_TYPES,
  changeMicStatus,
  changeSelfVideoStatus,
  enableTextChatMic,
  getMyMember,
  updateUserAudioExtraction,
  updateUserMicActive,
} from "../users";
import { getCurrentVoiceChannel, restartVAD } from "../voiceChannels";
import { AUDIO_EXTRACTION_EVENT_TYPES, EgressErrorStatus } from "./middleware";
import {
  getAudioExtractionDefaultMicStatus,
  getAutoRecordExternalMeeting,
  getAutoSelectedScreen,
  getEgressRoomName,
  getEnableUseOfAudioExtraction,
  getIsExtractingAudio,
  getIsLoadingRecordAudioExtraction,
  getIsRecordingAudioExtraction,
  getIsStartingRecordAudioExtraction,
  getRecordingStatusInWorkspace,
  getUseOfRecordAudioExtraction,
  getVideoTrackId,
} from "./selectors";
import { CheckIconOutlined, ErrorOutlineIconOutlined } from "../../components/icons/material";
import localData from "../../localStorageKeys";
import { sendElectronNotification } from "../../electron/sendNotification";
import { doStartScreenShare, doStopScreenShare, getMySharedScreen, saveSharedScreenInfo } from "../screenshare";
import { connectRoom, enableEgress, enableScreenshare, getLivekitConnectionStatus } from "../livekit";
import { sendMessageOverIPC } from "../../electron/sendMessageOverIPC";
import { channels } from "../../electron/channels";
import { getCurrentWorkspace, getLivekitUrl } from "../workspace";
import { getNoiseReductionSetting } from "../devices";
import { RecordingStatusInWorkspace } from "./reducer";
import { showModal } from "../../screens/Dashboard/state";
import { ELECTRON_SCREEN_SELECTOR_MODAL_ID } from "../../screens/Dashboard/constants";
import { getIsReachedSttMaxUsage, workspaceSpeechToTextLimitReached } from "../textChannel";
import * as ipc from "../../electron/ipc";
import { LogCategory, log } from "../../utils/log";
import { getCurrentTextChatWindowType } from "../account";

export enum AudioExtractionActions {
  UPDATE_AUDIO_EXTRACTION = "audioExtraction/UPDATE_AUDIO_EXTRACTION",
  SET_USER_MEDIA_STREAM = "audioExtraction/SET_USER_MEDIA_STREAM",
  SET_USE_AUDIO_EXTRACTION = "audioExtraction/SET_USE_AUDIO_EXTRACTION",
  SET_USE_RECORD_AUDIO_EXTRACTION = "audioExtraction/SET_USE_RECORD_AUDIO_EXTRACTION",
  UPDATE_RECORD_AUDIO_EXTRACTION = "audioExtraction/UPDATE_RECORD_AUDIO_EXTRACTION",
  SET_EGRESS_ID = "audioExtraction/SET_EGRESS_ID",
  SET_AUDIO_TRACK_ID = "audioExtraction/SET_AUDIO_TRACK_ID",
  SET_VIDEO_TRACK_ID = "audioExtraction/SET_VIDEO_TRACK_ID",
  UPDATE_PREPARE_RECORD_AUDIO_EXTRACTION = "audioExtraction/UPDATE_PREPARE_RECORD_AUDIO_EXTRACTION",
  UPDATE_STARTING_RECORD_AUDIO_EXTRACTION = "audioExtraction/UPDATE_STARTING_RECORD_AUDIO_EXTRACTION",
  UPDATE_STOPPING_RECORD_AUDIO_EXTRACTION = "audioExtraction/UPDATE_STOPPING_RECORD_AUDIO_EXTRACTION",
  SET_EGRESS_ROOM_NAME = "audioExtraction/SET_EGRESS_ROOM_NAME",
  SET_RECORDING_STATUS_IN_WORKSPACE = "audioExtraction/SET_RECORDING_STATUS_IN_WORKSPACE",
  SET_AUTO_SELECTED_SCREEN = "audioExtraction/SET_AUTO_SELECTED_SCREEN",
  SET_AUTO_RECORD_EXTERNAL_MEETING = "audioExtraction/SET_AUTO_RECORD_EXTERNAL_MEETING",
  SET_DEFAULT_MIC_STATUS = "audioExtraction/SET_DEFAULT_MIC_STATUS",
  SET_ENABLE_MIC = "audioExtraction/SET_ENABLE_MIC",
  SET_AUDIO_EXTRACTION_STARTED_AT = "audioExtraction/SET_AUDIO_EXTRACTION_STARTED_AT",
}

const logE = (event: string, ...args: any[]) => log(LogCategory.Egress, "##### " + event, ...args);

export type AudioExtractionActionTypes =
  | ReturnType<typeof updateAudioExtractionStateAction>
  | ReturnType<typeof setUserMediaStream>
  | ReturnType<typeof setUseOfAudioExtraction>
  | ReturnType<typeof updateRecordAudioExtractionStateAction>
  | ReturnType<typeof setAudioTrackId>
  | ReturnType<typeof setVideoTrackId>
  | ReturnType<typeof updateStartingRecordAudioExtractionAction>
  | ReturnType<typeof updateStoppingRecordAudioExtractionAction>
  | ReturnType<typeof setUseOfRecordAudioExtraction>
  | ReturnType<typeof setEgressRoomName>
  | ReturnType<typeof setRecordingStatusInWorkspace>
  | ReturnType<typeof setAutoSelectedScreen>
  | ReturnType<typeof setAutoRecordExternalMeeting>
  | ReturnType<typeof setAudioExtractionDefaultMicStatus>
  | ReturnType<typeof updateAudioExtractionStartedAt>;

export function updateAudioExtractionStateAction(isExtractingAudio: boolean) {
  return {
    type: AudioExtractionActions.UPDATE_AUDIO_EXTRACTION,
    payload: { isExtractingAudio },
  } as const;
}

export function updateAudioExtractionState(isExtractingAudio?: boolean): AppThunk {
  return (dispatch, getState) => {
    const currentAudioExtractionState = getIsExtractingAudio(getState());
    const isRecordingAudioExtraction = getIsRecordingAudioExtraction(getState());

    if (isRecordingAudioExtraction || isExtractingAudio === currentAudioExtractionState) {
      return;
    }

    const isReachedSttMaxUsage = getIsReachedSttMaxUsage(getState());

    if (isReachedSttMaxUsage) {
      dispatch(workspaceSpeechToTextLimitReached(true, false));
      return;
    }

    const me = getMyMember(getState());
    const currentVoiceChannel = getCurrentVoiceChannel(getState());

    dispatch(
      sendMessage(AUDIO_EXTRACTION_EVENT_TYPES.REQUEST_UPDATE_AUDIO_EXTRACTION, {
        workspaceId: me?.workspaceId,
        voiceChannelId: currentVoiceChannel?.id,
        isExtractingAudio: !currentAudioExtractionState,
      }),
    );
  };
}

export function updatedAudioExtractionState(
  voiceChannelId: number,
  isExtractingAudio: boolean,
  leftChannel?: boolean,
): AppThunk {
  return (dispatch, getState) => {
    const currentVoiceChannel = getCurrentVoiceChannel(getState());
    const currentAudioExtractionState = getIsExtractingAudio(getState());
    const isRecordingAudioExtraction = getIsRecordingAudioExtraction(getState());
    const defaultMicStatus = getAudioExtractionDefaultMicStatus(getState());
    const textChatWindowType = getCurrentTextChatWindowType(getState());

    if (
      isRecordingAudioExtraction ||
      voiceChannelId !== currentVoiceChannel?.id ||
      currentAudioExtractionState === isExtractingAudio
    )
      return;

    dispatch(updateAudioExtractionStateAction(isExtractingAudio));

    if (!isExtractingAudio) {
      dispatch(destroyTextChatWindowForAudioExtraction());
      dispatch(changeMicStatus(true));
      dispatch(updateAudioExtractionStartedAt(null));

      if (leftChannel) {
        Toast.success(
          intl.formatMessage({
            id: "live-translation/stopped-success",
            defaultMessage: "Live translation of other apps stopped successfully!",
          }),
        );
      }
    } else {
      dispatch(updateAudioExtractionStartedAt(new Date().getTime()));
      dispatch(enableTextChatMic(defaultMicStatus));
      dispatch(changeMicStatus(!defaultMicStatus));
      if (textChatWindowType === TextChatWindowType.TEXT_CHAT_WINDOW) {
        dispatch(openTextChatWindowForAudioExtraction());
      } else if (textChatWindowType === TextChatWindowType.SUBTITLE) {
        dispatch(showSubtitle());
      }
    }
  };
}

export function updateAudioExtractionStartedAt(startedAt: number | null) {
  return {
    type: AudioExtractionActions.SET_AUDIO_EXTRACTION_STARTED_AT,
    payload: { startedAt },
  } as const;
}

export function setUserMediaStream(userMediaStream: MediaStream | undefined) {
  return {
    type: AudioExtractionActions.SET_USER_MEDIA_STREAM,
    payload: { userMediaStream },
  } as const;
}

export function createdAudioExtractionTranscript(
  workspaceId: number,
  voiceChannelId: number,
  transcriptId?: number,
): AppThunk {
  return (dispatch, getState) => {
    if (!transcriptId || transcriptId < 0) {
      Toast.error(
        intl.formatMessage({
          id: "live-translation/failed-to-create-transcript",
          defaultMessage: "Failed to create transcript of other apps",
        }),
      );

      return;
    }

    const filePath = generateTranscriptContentWithParams(
      String(workspaceId),
      String(voiceChannelId),
      TRANSCRIPT_LIBRARY,
      String(transcriptId),
    );
    const key = "created-audio-extraction-transcript";

    notification.close(key);
    dispatch(addNotificationKey(key));
    notification.open({
      type: "success",
      key,
      icon: <CheckIconOutlined />,
      message: (
        <FormattedMessage id="live-translation/created-transcript" defaultMessage="Meeting Transcript was created!" />
      ),
      description: (
        <>
          <a href={filePath} target="_blank" rel="noreferrer">
            {intl.formatMessage({
              id: "live-translation/file-link",
              defaultMessage: "Transcript is here.",
            })}
          </a>
        </>
      ),
    });
  };
}

export function hydrateUseOfAudioExtraction(): AppThunk {
  return dispatch => {
    const useOfAudioExtraction: boolean =
      JSON.parse(localData.fetch("settings.workspace.useOfAudioExtraction")) ?? true;

    dispatch(setUseOfAudioExtraction(!!useOfAudioExtraction));
  };
}

function setUseOfAudioExtraction(status: boolean) {
  return {
    type: AudioExtractionActions.SET_USE_AUDIO_EXTRACTION,
    payload: { status },
  } as const;
}

export function toggleUseOfAudioExtraction(): AppThunk {
  return (dispatch, getState) => {
    try {
      const me = getMyMember(getState());

      if (!me) return;

      const useOfAudioExtraction = getEnableUseOfAudioExtraction(getState());

      localData.set("settings.workspace.useOfAudioExtraction", JSON.stringify(!useOfAudioExtraction));
      dispatch(setUseOfAudioExtraction(!useOfAudioExtraction));
      dispatch(updateAudioExtractionState(false));

      Toast.success(
        intl.formatMessage(
          {
            id: "workspace/toggle-use-of-live-translation",
            defaultMessage: "{status} Use of Live translation of other apps",
          },
          {
            status: !useOfAudioExtraction
              ? intl.formatMessage({
                  id: "event-log/event-type-enabled",
                  defaultMessage: "Enabled",
                })
              : intl.formatMessage({
                  id: "event-log/event-type-disabled",
                  defaultMessage: "Disabled",
                }),
          },
        ),
      );
    } catch (err) {
      Toast.error(
        intl.formatMessage({
          id: "workspace/toggle-use-of-live-translation-error",
          defaultMessage: "Failed to update use of Live translation of other apps",
        }),
      );
    }
  };
}

export function sendForgetToStartNotification(currentActiveWindowFullName: string, doRecord: boolean): AppThunk {
  return async (dispatch, getState) => {
    const isExtractingAudio = getIsExtractingAudio(getState());
    const isRecordingAudioExtraction = getIsRecordingAudioExtraction(getState());
    const isLoadingRecordAudioExtraction = getIsLoadingRecordAudioExtraction(getState());
    const autoRecordExternalMeeting = getAutoRecordExternalMeeting(getState());

    if (isExtractingAudio || isRecordingAudioExtraction || isLoadingRecordAudioExtraction || !autoRecordExternalMeeting)
      return;

    let sharingOptions: ipc.ScreenShareWindowSource | undefined;

    if (doRecord) {
      const sharingOptionsList = await ipc.getScreenWindowSharingOptions();

      sharingOptions = sharingOptionsList.find(sl => sl.name.includes(currentActiveWindowFullName));

      if (!sharingOptions) {
        sendElectronNotification({
          title: intl.formatMessage({
            id: "live-translation/send-forget-to-start-notification-title",
            defaultMessage: "Live translation doesn't start yet.",
          }),
          body: intl.formatMessage({
            id: "live-translation/send-forget-to-start-notification-body",
            defaultMessage: "You can start live translation from VoicePing app top menu bar.",
          }),
        });

        return;
      }
    }

    const key = "record-audio-extraction-notification";

    dispatch(
      addNotificationOnPIP({
        notificationsType: key,
        data: {
          currentActiveWindowFullName,
          sharingOptions,
          notificationKey: key,
          doRecord,
        },
      }),
    );
    dispatch(showNotificationWindow());

    const onCancel = () => {
      dispatch(removeNotificationOnPIP({ type: key }));
      dispatch(removeNotificationKey(key));
    };

    const onSuccess = () => {
      if (doRecord && sharingOptions) {
        dispatch(updateRecordAudioExtractionState());
        dispatch(setAutoSelectedScreen(sharingOptions.id, sharingOptions.name));
      } else if (!doRecord) {
        dispatch(updateAudioExtractionState());
      }

      onCancel();
    };

    dispatch(addNotificationKey(key));
    notification.open({
      key,
      icon: <ErrorOutlineIconOutlined />,
      type: "meeting",
      duration: 20,
      message: (
        <FormattedMessage
          id="live-translation/notification-start-title"
          defaultMessage="Meeting of other apps was detected."
        />
      ),
      description: (
        <>
          {doRecord ? (
            <FormattedMessage
              id="live-translation/notification-description-start-recording"
              defaultMessage='Would you like to start live translation and recording of "{name}" ?'
              values={{ name: currentActiveWindowFullName }}
            />
          ) : (
            <FormattedMessage
              id="live-translation/notification-description-start-translation"
              defaultMessage='Would you like to start live translation of "{name}" ?'
              values={{ name: currentActiveWindowFullName }}
            />
          )}
          {doRecord && sharingOptions && (
            <div className="notification-thumbnail-container">
              <img
                className="notification-thumbnail"
                src={sharingOptions.thumbnailDataURI || ""}
                alt={sharingOptions.name}
              />
            </div>
          )}
        </>
      ),
      primaryButton: {
        type: "primary",
        color: "positive",
        label: <FormattedMessage id="start" defaultMessage="Start" />,
        onClick: onSuccess,
      },
      secondaryButton: {
        type: "primary",
        color: "negative",
        label: <FormattedMessage id="cancel" defaultMessage="Cancel" />,
        onClick: onCancel,
      },
      onClose: onCancel,
    });
  };
}

export function updateStartingRecordAudioExtractionAction(isStartingRecordAudioExtraction: boolean) {
  return {
    type: AudioExtractionActions.UPDATE_STARTING_RECORD_AUDIO_EXTRACTION,
    payload: { isStartingRecordAudioExtraction },
  } as const;
}

export function updateStoppingRecordAudioExtractionAction(isStoppingRecordAudioExtraction: boolean) {
  return {
    type: AudioExtractionActions.UPDATE_STOPPING_RECORD_AUDIO_EXTRACTION,
    payload: { isStoppingRecordAudioExtraction },
  } as const;
}

export function updateRecordAudioExtractionStateAction(isRecordingAudioExtraction: boolean) {
  return {
    type: AudioExtractionActions.UPDATE_RECORD_AUDIO_EXTRACTION,
    payload: { isRecordingAudioExtraction },
  } as const;
}

export function updateRecordAudioExtractionState(): AppThunk {
  return (dispatch, getState) => {
    const isRecordingAudioExtraction = getIsRecordingAudioExtraction(getState());
    const isExtractingAudio = getIsExtractingAudio(getState());

    if (isExtractingAudio) return;

    logE("Update isRecordingAudioExtraction: ", isRecordingAudioExtraction);

    if (!isRecordingAudioExtraction) {
      dispatch(getEgressRoomToken());
    } else {
      dispatch(stopRecordingAudioExtraction());
    }
  };
}

export function startRecordingAudioExtraction(): AppThunk {
  return (dispatch, getState) => {
    const isRecordingAudioExtraction = getIsRecordingAudioExtraction(getState());
    const isExtractingAudio = getIsExtractingAudio(getState());

    if (isRecordingAudioExtraction || isExtractingAudio) {
      dispatch(updateStartingRecordAudioExtractionAction(false));
      return;
    }

    logE("Starting record audio extraction");

    const me = getMyMember(getState());
    const currentVoiceChannel = getCurrentVoiceChannel(getState());

    dispatch(
      sendMessage(AUDIO_EXTRACTION_EVENT_TYPES.REQUEST_UPDATE_RECORD_AUDIO_EXTRACTION, {
        workspaceId: me?.workspaceId,
        voiceChannelId: currentVoiceChannel?.id,
        voiceChannelShortId: currentVoiceChannel?.shortId,
        isRecordingAudioExtraction: true,
      }),
    );
  };
}

export function stopRecordingAudioExtraction(): AppThunk {
  return (dispatch, getState) => {
    const isStartingRecordAudioExtraction = getIsStartingRecordAudioExtraction(getState());
    const isRecordingAudioExtraction = getIsRecordingAudioExtraction(getState());

    if (!isStartingRecordAudioExtraction && !isRecordingAudioExtraction) return;

    logE("Stopping record audio extraction...");

    const me = getMyMember(getState());
    const currentVoiceChannel = getCurrentVoiceChannel(getState());

    dispatch(
      sendMessage(AUDIO_EXTRACTION_EVENT_TYPES.REQUEST_UPDATE_RECORD_AUDIO_EXTRACTION, {
        workspaceId: me?.workspaceId,
        voiceChannelId: currentVoiceChannel?.id,
        voiceChannelShortId: currentVoiceChannel?.shortId,
        isRecordingAudioExtraction: false,
      }),
    );
    dispatch(updateStoppingRecordAudioExtractionAction(true));
    sendMessageOverIPC(channels.SHOW_APP);
  };
}

export function updatedRecordAudioExtractionState(
  isRecordingAudioExtraction: boolean,
  accessToken?: string | null,
): AppThunk {
  return (dispatch, getState) => {
    const isStartingRecordAudioExtraction = getIsStartingRecordAudioExtraction(getState());
    const videoTrackId = getVideoTrackId(getState());

    if (isRecordingAudioExtraction && isStartingRecordAudioExtraction) {
      dispatch(updateRecordAudioExtractionStateAction(true));

      if (videoTrackId) {
        dispatch(startedRecordAudioExtraction());
      }
    } else {
      logE("Stopped record audio extraction.");

      dispatch(updateStoppingRecordAudioExtractionAction(true));
      dispatch(changeRoom(accessToken));
      dispatch(updateStartingRecordAudioExtractionAction(false));
      dispatch(updateRecordAudioExtractionStateAction(false));
    }
  };
}

export function startedRecordAudioExtraction(): AppThunk {
  return (dispatch, getState) => {
    logE("Started record audio extraction.");

    sendElectronNotification({
      title: intl.formatMessage({
        id: "live-translation/send-recording-start-notification-title",
        defaultMessage: "Recording has started!!",
      }),
      body: intl.formatMessage({
        id: "live-translation/send-recording-start-notification-body",
        defaultMessage: "Recording of selected screen have started.",
      }),
    });

    const defaultMicStatus = getAudioExtractionDefaultMicStatus(getState());

    dispatch(enableTextChatMic(defaultMicStatus));
    dispatch(changeMicStatus(!defaultMicStatus));
    dispatch(updateStartingRecordAudioExtractionAction(false));
    dispatch(openTextChatWindowForAudioExtraction());
  };
}

export function setAudioTrackId(audioTrackId?: string) {
  return {
    type: AudioExtractionActions.SET_AUDIO_TRACK_ID,
    payload: { audioTrackId },
  } as const;
}

export function setVideoTrackId(videoTrackId?: string) {
  return {
    type: AudioExtractionActions.SET_VIDEO_TRACK_ID,
    payload: { videoTrackId },
  } as const;
}

export function createdEgressRecordingPath(recordingPath?: string): AppThunk {
  return (dispatch, getState) => {
    if (recordingPath) {
      const key = "created-record-audio-extraction";

      notification.close(key);
      dispatch(addNotificationKey(key));
      notification.open({
        type: "success",
        key,
        icon: <CheckIconOutlined />,
        message: <FormattedMessage id="record-audio-extraction/created" defaultMessage="Your meeting was recorded." />,
        description: (
          <>
            <a href={recordingPath} target="_blank" rel="noreferrer">
              {intl.formatMessage({
                id: "record-audio-extraction/file-link",
                defaultMessage: "Recording is here.",
              })}
            </a>
          </>
        ),
      });
    } else {
      Toast.error(
        intl.formatMessage({
          id: "record-audio-extraction/create-fail",
          defaultMessage: "Failed to create Recording.",
        }),
      );
    }
  };
}

export function gotEgressError(accessToken?: string | null, errorStatus?: EgressErrorStatus): AppThunk {
  return (dispatch, getState) => {
    logE("Got egress error.", errorStatus);

    const isStartingRecordAudioExtraction = getIsStartingRecordAudioExtraction(getState());
    const isRecordingAudioExtraction = getIsRecordingAudioExtraction(getState());

    dispatch(updateStoppingRecordAudioExtractionAction(true));
    dispatch(updateRecordAudioExtractionStateAction(false));
    dispatch(updateStartingRecordAudioExtractionAction(false));

    if (isStartingRecordAudioExtraction || isRecordingAudioExtraction) {
      sendMessageOverIPC(channels.SHOW_APP);

      if (errorStatus === "notAvailble") {
        Toast.error(
          intl.formatMessage({
            id: "record-audio-extraction/server-not-available",
            defaultMessage: "Failed to start recording due to current access congestion, please try again later.",
          }),
        );
      } else {
        Toast.error(
          intl.formatMessage({
            id: "record-audio-extraction/stopped-error",
            defaultMessage: "Something went wrong and Recording of other apps stopeed",
          }),
        );
      }
    }

    if (accessToken) {
      dispatch(changeRoom(accessToken));
    } else {
      dispatch(updateStoppingRecordAudioExtractionAction(false));
    }
  };
}

function setUseOfRecordAudioExtraction(status: boolean) {
  return {
    type: AudioExtractionActions.SET_USE_RECORD_AUDIO_EXTRACTION,
    payload: { status },
  } as const;
}

export function hydrateUseOfRecordAudioExtraction(): AppThunk {
  return dispatch => {
    const recordAudioExtraction: boolean =
      JSON.parse(localData.fetch("settings.workspace.recordAudioExtraction")) ?? true;

    dispatch(setUseOfRecordAudioExtraction(!!recordAudioExtraction));
  };
}

export function toggleUseOfRecordAudioExtraction(): AppThunk {
  return (dispatch, getState) => {
    const me = getMyMember(getState());

    if (!me) return;

    const recordAudioExtraction = getUseOfRecordAudioExtraction(getState());

    localData.set("settings.workspace.recordAudioExtraction", JSON.stringify(!recordAudioExtraction));
    dispatch(setUseOfRecordAudioExtraction(!recordAudioExtraction));
  };
}

export function getEgressRoomToken(): AppThunk {
  return (dispatch, getState) => {
    logE("Getting egress room token...");

    const me = getMyMember(getState());

    if (me) {
      dispatch(
        sendMessage(AUDIO_EXTRACTION_EVENT_TYPES.REQUEST_GET_EGRESS_ROOM_TOKEN, {
          workspaceId: me.workspaceId,
          voiceChannelId: me.voiceChannelId,
        }),
      );
      dispatch(updateStartingRecordAudioExtractionAction(true));
    }
  };
}

export function setEgressRoomName(room: string) {
  return {
    type: AudioExtractionActions.SET_EGRESS_ROOM_NAME,
    payload: { room },
  } as const;
}

export function gotEgressRoomToken(egressToken: string, room: string): AppThunk {
  return (dispatch, getState) => {
    logE("Got egress room token.");

    dispatch(changeRoom(egressToken));
    dispatch(setEgressRoomName(room));

    const screenshare = getAutoSelectedScreen(getState());

    if (window.electron && !screenshare.id) {
      dispatch(doStartScreenShare());
    }
  };
}

export function changeRoom(accessToken?: string | null): AppThunk {
  return async (dispatch, getState) => {
    logE("Changing livekit room...");

    const me = getMyMember(getState());
    const noiseReduction = getNoiseReductionSetting(getState());
    const workspace = getCurrentWorkspace(getState());
    const livekitUrl = getLivekitUrl(getState());
    const livekitConnection = getLivekitConnectionStatus(getState());

    if (!livekitUrl) return;

    dispatch(changeSelfVideoStatus(false));
    dispatch(doStopScreenShare());

    if (!accessToken || livekitConnection !== "connected") {
      dispatch(disconnectSocket());
      return;
    }

    if (me) {
      if (workspace) {
        dispatch(restartVAD({ restartVAD: 0 }));
      }

      dispatch(
        connectRoom({
          url: livekitUrl,
          token: accessToken,
          autoSubscribe: true,
          noiseReduction,
          iceTransportPolicy: !!workspace?.iceTransportPolicy,
        }),
      );
    }
  };
}

export function publishEgressTrack(roomName?: string): AppThunk {
  return (dispatch, getState) => {
    logE("Publishing egress track...");

    const room = getEgressRoomName(getState());
    const screenshare = getMySharedScreen(getState());
    const autoSelectedScreen = getAutoSelectedScreen(getState());

    if (room === roomName) {
      dispatch(startRecordingAudioExtraction());
      dispatch(enableEgress());

      if (autoSelectedScreen.id) {
        dispatch(enableScreenshare(autoSelectedScreen.id));
        dispatch(saveSharedScreenInfo({ sourceId: autoSelectedScreen.id, sourceName: autoSelectedScreen.name }));
      } else if (screenshare.id) {
        dispatch(enableScreenshare(screenshare.id));
      } else if (!window.electron) {
        dispatch(doStartScreenShare());
      }
    } else {
      dispatch(gotEgressError());
    }
  };
}

export function setRecordingStatusInWorkspace(recordingStatusInWorkspace: RecordingStatusInWorkspace) {
  return {
    type: AudioExtractionActions.SET_RECORDING_STATUS_IN_WORKSPACE,
    payload: { recordingStatusInWorkspace },
  } as const;
}

export function setRecordingStatus(recordingStatusInWorkspace: RecordingStatusInWorkspace): AppThunk {
  return async (dispatch, getState) => {
    const currenstStatus = getRecordingStatusInWorkspace(getState());

    if (currenstStatus === recordingStatusInWorkspace) return;

    recordingStatusInWorkspace.userIds.forEach(userId => {
      dispatch(updateUserAudioExtraction({ isExtractingAudio: true, userId }));
    });

    currenstStatus.userIds.forEach(userId => {
      if (!recordingStatusInWorkspace.userIds.includes(userId)) {
        dispatch(updateUserAudioExtraction({ isExtractingAudio: false, userId }));
      }
    });

    dispatch(setRecordingStatusInWorkspace(recordingStatusInWorkspace));
  };
}

export function cancelRecordAudioExtraction(): AppThunk {
  return async (dispatch, getState) => {
    const isStartingRecordAudioExtraction = getIsStartingRecordAudioExtraction(getState());

    if (!isStartingRecordAudioExtraction) return;

    logE("Canceling egress...");

    const me = getMyMember(getState());
    const voiceChannel = getCurrentVoiceChannel(getState());

    dispatch(updateStoppingRecordAudioExtractionAction(true));
    dispatch(updateRecordAudioExtractionStateAction(false));
    dispatch(updateStartingRecordAudioExtractionAction(false));
    dispatch(
      sendMessage(AUDIO_EXTRACTION_EVENT_TYPES.REQUEST_CANCEL_RECORD_AUDIO_EXTRACTION, {
        workspaceId: me?.workspaceId,
        voiceChannelShortId: voiceChannel?.shortId,
      }),
    );
  };
}

export function canceledRecordAudioExtraction(accessToken?: string | null): AppThunk {
  return async dispatch => {
    logE("Canceled egress...");

    Toast.success(
      intl.formatMessage({
        id: "record-audio-extraction/cancel",
        defaultMessage: "The recording was cancelled.",
      }),
    );
    dispatch(updateStartingRecordAudioExtractionAction(false));
    dispatch(changeRoom(accessToken));
  };
}

export function gotEgressRecordingLimitReached(accessToken?: string | null): AppThunk {
  return async dispatch => {
    dispatch(updateStoppingRecordAudioExtractionAction(true));
    dispatch(updateRecordAudioExtractionStateAction(false));
    sendMessageOverIPC(channels.SHOW_APP);
    Toast.success(
      intl.formatMessage({
        id: "record-audio-extraction/limit-reached",
        defaultMessage: "Recording stopped due to 1 hour max recording time limitation.",
      }),
    );

    dispatch(changeRoom(accessToken));
  };
}

export function disableEgress(): AppThunk {
  return async (dispatch, getState) => {
    logE("Disabling egress...");

    const me = getMyMember(getState());

    dispatch(setAutoSelectedScreen(undefined, undefined));
    dispatch(setAudioTrackId(undefined));
    dispatch(setVideoTrackId(undefined));
    dispatch(
      showModal({
        id: ELECTRON_SCREEN_SELECTOR_MODAL_ID,
        show: false,
      }),
    );

    if (me) {
      dispatch(
        sendMessage(USER_EVENT_TYPES.USER_OUT_MUTE_REQUEST, {
          workspaceId: me.workspaceId,
          mute: true,
        }),
      );
      dispatch(
        updateUserMicActive({
          userId: me.id,
          mute: true,
        }),
      );
    }
  };
}

export function sendScreenConnectionAlert(): AppThunk {
  return async (dispatch, getState) => {
    sendElectronNotification({
      title: intl.formatMessage({
        id: "live-translation/screen-connection-lost-title",
        defaultMessage: "Recording screen connection lost",
      }),
      body: intl.formatMessage({
        id: "live-translation/screen-connection-lost-body",
        defaultMessage: "Please record screen from VoicePing app bottom menu again.",
      }),
    });
  };
}

export function setAutoSelectedScreen(id?: string, name?: string) {
  return {
    type: AudioExtractionActions.SET_AUTO_SELECTED_SCREEN,
    payload: { autoSelectedScreen: { id, name } },
  } as const;
}

function setAutoRecordExternalMeeting(autoRecordExternalMeeting: boolean) {
  return {
    type: AudioExtractionActions.SET_AUTO_RECORD_EXTERNAL_MEETING,
    payload: { autoRecordExternalMeeting },
  } as const;
}

export function hydrateAutoRecordExternalMeeting(): AppThunk {
  return dispatch => {
    const autoRecordExternalMeeting: boolean = JSON.parse(
      localData.fetch("settings.workspace.autoRecordExternalMeeting") ?? true,
    );

    dispatch(setAutoRecordExternalMeeting(autoRecordExternalMeeting));
  };
}

export function toggleAutoRecordExternalMeeting(): AppThunk {
  return (dispatch, getState) => {
    const autoRecordExternalMeeting = getAutoRecordExternalMeeting(getState());

    localData.set("settings.workspace.autoRecordExternalMeeting", JSON.stringify(!autoRecordExternalMeeting));
    dispatch(setAutoRecordExternalMeeting(!autoRecordExternalMeeting));
  };
}

function setAudioExtractionDefaultMicStatus(defaultMicStatus: boolean) {
  return {
    type: AudioExtractionActions.SET_DEFAULT_MIC_STATUS,
    payload: { defaultMicStatus },
  } as const;
}

export function hydrateAudioExtractionDefaultMicStatus(): AppThunk {
  return dispatch => {
    const defaultMicStatus: boolean = JSON.parse(
      localData.fetch("settings.workspace.audioExtractionDefaultMicStatus") ?? true,
    );

    dispatch(setAudioExtractionDefaultMicStatus(defaultMicStatus));
  };
}

export function toggleAudioExtractionDefaultMicStatus(): AppThunk {
  return (dispatch, getState) => {
    const defaultMicStatus = getAudioExtractionDefaultMicStatus(getState());

    localData.set("settings.workspace.audioExtractionDefaultMicStatus", JSON.stringify(!defaultMicStatus));
    dispatch(setAudioExtractionDefaultMicStatus(!defaultMicStatus));
  };
}
