import { getJson, postJson } from "../utils/network";
import {
  USER_APP_ICON_PATH,
  RESET_PASSWORD,
  CHECK_CMS_PASSWORD,
  GET_CMS_PERMISSION,
  CMSPermission,
  UPDATE_CMS_PERMISSION,
  SEARCH_QUERY_PARAM_NAME,
  ACCEPT_INVITATION_URL,
  UPDATE_USER_WORKSPACE,
  POST_FCM_DEVICE_TOKEN,
  POST_UPDATE_MAIL_NOTIFICATION,
  POST_UPDATE_TEXT_CHAT_WINDOW_TYPE,
} from "../constant";
import { CreateOrReadResponseBody, UpdateOrDeleteResponseBody } from ".";
import { UpdateNotificationPayload } from "../screens/Dashboard/Settings/SettingNotification";
import { TextChatWindowType } from "../store/types";

export async function getUserAppIcon(hash: string, type: string) {
  const path = USER_APP_ICON_PATH(hash);
  const { response } = await getJson(path, { mimeType: type });

  if (response.data?.exists) {
    return response.data.url;
  } else {
    return undefined;
  }
}

export async function postUserAppIcon(hash: string, iconDataURI: string) {
  const path = USER_APP_ICON_PATH(hash);
  const { response } = await postJson(path, { iconDataURI });

  return response.data.url;
}

export async function postResetPassword({
  token,
  newPassword,
  browser,
  country,
  platform,
}: {
  token: string;
  newPassword: string;
  browser: string;
  platform: string;
  country: string;
}) {
  const { response } = await postJson(RESET_PASSWORD, { token, newPassword, browser, country, platform });

  return response;
}

export async function checkCmsAdminPassword(data: { password: string; workspaceId: number }) {
  const path = CHECK_CMS_PASSWORD;
  const { response } = await postJson(path, data);

  if (response.success) {
    return response.data;
  } else {
    return undefined;
  }
}

export type CmsPermission = {
  userId: number;
  workspaceId: number;
  displayName: string;
  avatarUrl: string;
  cmsPermission: number;
};

type CmsPermissionResponse = CreateOrReadResponseBody<{
  result: {
    rows: CmsPermission[];
    count: number;
  };
}>;

export async function getCmsPermission(search?: string, limit?: number, offset?: number) {
  let path = GET_CMS_PERMISSION;

  if (search && search !== "") {
    path = path + `?${SEARCH_QUERY_PARAM_NAME}=${search}`;
  }

  const { response } = await postJson<CmsPermissionResponse>(path, { limit, offset });

  if (response.success) {
    return response.data.result;
  } else {
    throw new Error("Error when fetching user global CMS permission data with email");
  }
}

export async function updateCmsPermission(data: {
  userId: number;
  workspaceId: number;
  newPermission: boolean;
  pageToApply: CMSPermission;
}) {
  const path = UPDATE_CMS_PERMISSION;
  const { response } = await postJson<UpdateOrDeleteResponseBody>(path, data);

  if (response.success) {
    return response;
  } else {
    throw new Error("Error when update user global CMS permission data");
  }
}

export async function updateUserWorkspace(workspaceId: number) {
  const path = UPDATE_USER_WORKSPACE;
  const { response } = await postJson<UpdateOrDeleteResponseBody>(path, { workspaceId });

  if (response.success) {
    return response;
  } else {
    throw new Error(response.errors[0].message);
  }
}

export type WorkspaceCmsPermission = {
  userId: number;
  workspaceId: number;
  displayName: string;
  avatarUrl: string;
  cmsPermission: number;
};

type acceptInvitationResponse = CreateOrReadResponseBody<{
  workspaceId: number;
  workspaceShortId: string;
  isEnableEmailTokenFlagOnWorkspace: boolean;
}>;

interface acceptInvitationRequest {
  invitationToken: string;
}

export async function acceptInvitation(data: acceptInvitationRequest) {
  const path = ACCEPT_INVITATION_URL;
  const { response } = await postJson<acceptInvitationResponse>(path, data);

  return response;
}

export async function postFCMToken(webDeviceToken: string | null) {
  const path = POST_FCM_DEVICE_TOKEN;
  const { response } = await postJson<CreateOrReadResponseBody>(path, { webDeviceToken });

  if (response.success) {
    return response;
  } else {
    throw new Error(`Error while storing web FCM Token: ${JSON.stringify(response.errors)}`);
  }
}

export async function postUpdateMailNotification(data: UpdateNotificationPayload) {
  const path = POST_UPDATE_MAIL_NOTIFICATION;
  const { response } = await postJson<CreateOrReadResponseBody<UpdateNotificationPayload>>(path, data);

  return response;
}

export async function postUpdateTextChatWindowType(textChatWindowType: TextChatWindowType) {
  const path = POST_UPDATE_TEXT_CHAT_WINDOW_TYPE;
  const { response } = await postJson<CreateOrReadResponseBody<{ result: TextChatWindowType }>>(path, {
    textChatWindowType,
  });
  return response;
}
