import React from "react";
import { FormattedMessage } from "react-intl";
import { Action } from "redux";

import * as ipc from "../../electron/ipc";
import {
  AppThunk,
  MacPermission,
  MacRequiredPermissionModalData,
  NotificationWindowPosition,
  TextChatWindowType,
} from "../../store/types";
import { AppState, AudioEffect, VirtualBackgroundType } from "./reducer";

import { USER_EVENT_TYPES, changeMicStatus, getMyMember, sendEventAppStartUpPreference } from "../users";
import { getSocketConnectivityStatus, sendMessage } from "../socket";

import {
  getActiveVBG,
  getApp,
  getOpenTextChatInAnotherWindow,
  getPipDefaultScreenshareWindowStatus,
  getSoundEffectEnbaled,
  getStopSharingWindowOpeningStatus,
  getTextChatWindowLastPosition,
} from "./selectors";
import { downloadApp } from "../../utils/appDownload";
import { getAccountWorkspaceId, getCurrentTextChatWindowType, getLanguage } from "../account/selectors";
import { setStartUpPreference, getStartUpPreference } from "../../utils/startup-launch";
import { intl } from "../../i18n";
import { showModal } from "../../screens/Dashboard/state";
import { DUPLICATE_LOGIN_MODAL_ID, ELECTRON_REQUIRED_MAC_PERMISSION_MODAL_ID } from "../../screens/Dashboard/constants";
import { channels } from "../../electron/channels";
import { Config } from "../../electron/config";
import { sendMessageOverIPC } from "../../electron/sendMessageOverIPC";
import {
  generateOnlineLogPath,
  getTextChatFloorAdditionalOptions,
  getTextChatMeetingRoomAdditionalOptions,
  joinWorkspaceByShortId,
  openPath,
} from "../../utils/helpers";
import { getAudioSource } from "../../utils/playSoundEffect";
import localData, {
  ShowOriginalTextLocalStorageClient,
  WorkspacePhraseLocalStorageClient,
} from "../../localStorageKeys";
import { Toast } from "../../components/antd/Toast";
import { notification } from "../../components/antd/Notification";
import { WarningAmberIconOutlined } from "../../components/icons/material";
import { connectSlackAccount, setTextChatWindowType, updateShowOriginalMessage } from "../account/actions";

import { getCurrentWorkspace, getAllWorkspaces } from "../workspace";
import {
  LOGIN_WINDOW_ROUTE,
  NOTIFICATIONS_WINDOW_ROUTE,
  SCREENSHARE_OVERLAY_WINDOW,
  STOP_REMOTE_CONTROL_WINDOW,
  STOP_SHARING_WINDOW_ROUTE,
  SUBTITLE_WINDOW_ROUTE,
  TEXT_CHAT_WINDOW_FOR_AUDIO_EXTRACTION_ROUTE,
  TEXT_CHAT_WINDOW_ROUTE,
  TRANSCRIPT_LIBRARY,
  generateMeetingLogWithParams,
} from "../../routes";
import { getNotificationWindowLastPosition } from ".";
import {
  NOTIFICATION_WINDOW_INITIAL_HEIGHT,
  NOTIFICATION_WINDOW_INITIAL_WIDTH,
  NOTIFICATION_WINDOW_DEFAULT_POSITION_X,
  NOTIFICATION_WINDOW_DEFAULT_POSITION_Y,
  PRELOADED_VIRTUAL_BACKGROUNDS,
  PRIMARY_WINDOW_ID,
} from "../../constant";
import { PlaySoundEffectTypes } from "../../utils/playSoundEffect";
import {
  getCurrentVoiceChannel,
  getIsChannelIsLoading,
  getIsChannelJoining,
  getOnlineUserIdsOnFloor,
  getTextChatAllowMembers,
  voiceChannelInviteSendResponse,
} from "../voiceChannels";
import { hydrateDevices } from "../devices";
import { State } from "../state";
import { getMySharedScreen } from "../screenshare";
import { canShowNotificationWindow } from "../livekit";
import { checkExistingNotifications } from "../notificationsWindow";
import {
  TEXT_CHANNEL_EVENT_TYPES,
  checkUnreadMessages,
  hideChatBoxInMainWindow,
  setDMUserIds,
  setFocusChatBoxAction,
  showChatBox,
} from "../textChannel";
import {
  getIsExtractingAudio,
  getIsLoadingRecordAudioExtraction,
  getIsRecordingAudioExtraction,
  hydrateAutoRecordExternalMeeting,
  hydrateUseOfAudioExtraction,
  hydrateUseOfRecordAudioExtraction,
  hydrateAudioExtractionDefaultMicStatus,
} from "../audioExtraction";
import { requestWebNotificationPermission } from "../../init/firebase";
import { CHANNEL_EVENT_TYPES } from "../voiceChannels/actions-enum";
import { zohoToggleWindow } from "../../utils/zoho";

export enum AppActions {
  SET_LOADING = "app/SET_LOADING",
  SET_WORKSPACE_UPDATING = "app/SET_WORKSPACE_UPDATING",
  APP_INTERVAL = "app/interval",
  SET_AUDIO_EFFECT = "app/SET_AUDIO_EFFECT",
  SET_PROCESS_ID = "app/SET_PROCESS_ID",
  SET_SERVER_IP_ADDRESS = "app/SET_SERVER_IP_ADDRESS",
  UPDATE_TOP_BAR_CONFIG = "app/UPDATE_TOP_BAR_CONFIG",
  SET_PERMISSION = "app/SET_PERMISSION",
  SET_UPDATE_PERCENT = "app/SET_UPDATE_PERCENT",
  SET_STARTUP = "app/SET_STARTUP",
  APP_WAITING_CLOSURE = "app/APP_WAITING_CLOSURE",
  SET_MAXIMIZED = "app/SET_MAXIMIZED",
  SET_APP_FOCUS_STATUS = "app/SET_APP_FOCUS_STATUS",
  SET_NOTIFICATION_WINDOW_LAST_POSITION = "app/SET_NOTIFICATION_WINDOW_LAST_POSITION",
  SET_TEXT_CHAT_WINDOW_LAST_POSITION = "app/SET_TEXT_CHAT_WINDOW_LAST_POSITION",
  SET_DUPLICATED_LOGIN = "app/SET_DUPLICATED_LOGIN",
  SET_FORCE_RELOAD = "app/SET_FORCE_RELOAD",
  SET_VIRTUAL_BACKGROUNDS = "app/SET_VIRTUAL_BACKGROUNDS",
  ADD_VIRTUAL_BACKGROUND = "app/ADD_VIRTUAL_BACKGROUND",
  REMOVE_VIRTUAL_BACKGROUND = "app/REMOVE_VIRTUAL_BACKGROUND",
  SET_ACTIVE_VIRTUAL_BACKGROUND_IMAGE = "app/SET_ACTIVE_VIRTUAL_BACKGROUND_IMAGE",
  SET_IS_CUSTOMIZING_OFFICE_BACKGROUND = "app/SET_IS_CUSTOMIZING_OFFICE_BACKGROUND",
  SET_ACTIVE_PHRASE_LANGUAGE = "app/SET_ACTIVE_PHRASE_LANGUAGE",
  SWITCH_INACTIVE_10SEC = "app/SWITCH_INACTIVE_10SEC",
  SET_SOUND_EFFECT = "app/SET_SOUND_EFFECT",
  SET_INITIAL_ACCESS_WORKSPACE_CHANNEL_ID = "app/SET_INITIAL_ACCESS_WORKSPACE_CHANNEL_ID",
  SET_SILENCE_BUFFER_NODE = "app/SET_SILENCE_BUFFER_NODE",
  SET_WEB_CAMERA_STATUS = "app/SET_WEB_CAMERA_STATUS",
  SET_STOP_SHARING_WINDOW_OPENING_STATUS = "app/SET_STOP_SHARING_WINDOW_OPENING_STATUS",
  SET_USE_AUDIO_EXTRACTION = "app/SET_USE_AUDIO_EXTRACTION",
  SET_IS_OPEN_TEXT_CHAT_WINDOW = "app/SET_IS_OPEN_TEXT_CHAT_WINDOW",
  SET_OPEN_TEXT_CHAT_IN_ANOTHER_WINDOW = "app/SET_OPEN_TEXT_CHAT_IN_ANOTHER_WINDOW",
  SET_IDLE_FEATURE_STATUS = "app/SET_IDLE_FEATURE_STATUS",
  SET_PIP_DEFAULT_SCREENSHARE_STATUS = "app/SET_PIP_DEFAULT_SCREENSHARE_STATUS",
  SET_IS_SUBTITLE_OPENED = "app/SET_IS_SUBTITLE_OPENED",
}

export interface SetLoadingAction extends Action {
  type: AppActions.SET_LOADING;
  payload: boolean;
}

export interface SetWorkspaceUpdatingAction extends Action {
  type: AppActions.SET_WORKSPACE_UPDATING;
  payload: boolean;
}

export interface SetUpdatePercentageAction extends Action {
  type: AppActions.SET_UPDATE_PERCENT;
  payload: { downloadedPercentage: number };
}

export interface SetStartupAction extends Action {
  type: AppActions.SET_STARTUP;
  payload: { startup: boolean };
}

export interface SetMaximizedAction extends Action {
  type: AppActions.SET_MAXIMIZED;
  payload: { maximized: boolean };
}

export interface SwitchInActiveAction extends Action {
  type: AppActions.SWITCH_INACTIVE_10SEC;
  payload: { inActive: boolean };
}

export type AppActionTypes =
  | SetLoadingAction
  | SetWorkspaceUpdatingAction
  | SetUpdatePercentageAction
  | SetStartupAction
  | SetMaximizedAction
  | ReturnType<typeof setPermissionAction>
  | ReturnType<typeof setAudioEffectAction>
  | ReturnType<typeof closeAppWhenSafeAction>
  | ReturnType<typeof updateNotificationConfigAction>
  | ReturnType<typeof closeAppWhenSafeAction>
  | ReturnType<typeof setAppFocusStatus>
  | ReturnType<typeof setDuplicatedLoginAction>
  | ReturnType<typeof setNotificationWindowLastPositionAction>
  | ReturnType<typeof setForceReloadAction>
  | ReturnType<typeof setVirtualBackgrounds>
  | ReturnType<typeof addVirtualBackgroundAction>
  | ReturnType<typeof removeVirtualBackgroundAction>
  | ReturnType<typeof selectActiveVirtualBackgroundImage>
  | ReturnType<typeof setIsCustomizingOfficeBackgroundAction>
  | ReturnType<typeof setWorkspacePhraseLanguageAction>
  | SwitchInActiveAction
  | ReturnType<typeof setSoundEffect>
  | ReturnType<typeof setInitialAccessChannelIdAction>
  | ReturnType<typeof setSilenceBufferNode>
  | ReturnType<typeof setWebCameraStatus>
  | ReturnType<typeof setStopSharingWindowOpeningStatus>
  | ReturnType<typeof setTextChatWindowLastPositionAction>
  | ReturnType<typeof setIsOpeningTextChatWindow>
  | ReturnType<typeof setOpenTextChatInAnotherWindowAction>
  | ReturnType<typeof setIdleFeatureStatus>
  | ReturnType<typeof setPipDefaultScreenshareWindowStatus>
  | ReturnType<typeof setIsSubtitleOpened>;

export interface ScreenShareWindowSource {
  id: string;
  type: "screen" | "window";
  name: string;
  appIconDataURI: string | null;
  thumbnailDataURI: string | null;
}

export function hydrate(): AppThunk {
  return (dispatch: Function) => {
    dispatch(temporaryHydrateLocalData());
    localData.load();

    if (window.location.pathname.startsWith("/dashboard")) {
      dispatch(hydrateWindow());
      dispatch(hydrateDevices());
      dispatch(hydrateNotificationConfig());
      dispatch(hydrateNotificationWindowLastPosition());
      dispatch(hydrateTextChatWindowLastPosition());
      dispatch(hydrateShowOriginalMessage());
    }

    dispatch(hydrateStartup());
    dispatch(hydrateSoundEffect());
    dispatch(hydrateWebCameraStatus());
    dispatch(hydrateStopSharingWindowOpeningStatus());
    dispatch(hydrateUseOfAudioExtraction());
    dispatch(hydrateUseOfRecordAudioExtraction());
    dispatch(hydrateOpenTextChatInAnotherWindow());
    dispatch(hydrateIdleFeatureStatus());
    dispatch(hydratePipDefaultScreenshareWindowStatus());
    dispatch(hydrateAutoRecordExternalMeeting());
    dispatch(hydrateAudioExtractionDefaultMicStatus());
  };
}

//TODO - Remove this function by April 2021
export function temporaryHydrateLocalData(): AppThunk {
  return (dispatch: Function) => {
    if (localStorage.getItem("startup") !== null) {
      localData.tempSet("settings.app.startup", localStorage.getItem("startup"));
      localData.tempSet("settings.device.audio.input.id", localStorage.getItem("audioInDeviceId"));
      localData.tempSet("settings.device.audio.input.label", localStorage.getItem("audioInDeviceLabel"));
      localData.tempSet("settings.device.audio.output.id", localStorage.getItem("audioOutDeviceId"));
      localData.tempSet("settings.device.audio.output.label", localStorage.getItem("audioOutDeviceLabel"));
      localData.tempSet(
        "notification.promoteWebVersionOnMobile.closeCount",
        localStorage.getItem("topBar.promoteWebVersionOnMobile.closeCount"),
      );
      localData.tempSet(
        "notification.warnMissingSlackScopes.closeCount",
        localStorage.getItem("topBar.warnMissingSlackScopes.closeCount"),
      );

      localStorage.removeItem("startup");
      localStorage.removeItem("audioInDeviceId");
      localStorage.removeItem("audioInDeviceLabel");
      localStorage.removeItem("audioOutDeviceId");
      localStorage.removeItem("audioOutDeviceLabel");
      localStorage.removeItem("topBar.promoteWebVersionOnMobile.closeCount");
      localStorage.removeItem("topBar.warnMissingSlackScopes.closeCount");
    }
  };
}

export function hydrateVBG(): AppThunk {
  return async (dispatch: Function, getState: () => State) => {
    const activeVBGInLocalStorage = localData.fetch("settings.activeVBG.id");

    const vbgInLocalStorage: VirtualBackgroundType[] = JSON.parse(localData.fetch("settings.virtualBackgrounds"));
    let vbgImages: VirtualBackgroundType[] = PRELOADED_VIRTUAL_BACKGROUNDS.map((el, index) => ({
      id: index.toString(),
      url: el.url,
    }));

    if (vbgInLocalStorage.length > 0) {
      vbgImages = [...vbgImages, ...vbgInLocalStorage];
    }

    if (activeVBGInLocalStorage) {
      dispatch(selectActiveVirtualBackgroundImage(activeVBGInLocalStorage));
    }

    dispatch(setVirtualBackgrounds(vbgImages));

    const activeVBG = getActiveVBG(getState());

    if (!activeVBG || !activeVBGInLocalStorage || !vbgImages.some(el => el.id === activeVBG?.id)) {
      dispatch(selectActiveVirtualBackgroundImage(vbgImages[0].id));
      localData.set("settings.activeVBG.id", vbgImages[0].id);
    }
  };
}

function setWorkspacePhraseLanguageAction(language: string) {
  return {
    type: AppActions.SET_ACTIVE_PHRASE_LANGUAGE,
    payload: { language },
  } as const;
}

export function hydrateWorkspacePhraseLanguage(workspaceId: number): AppThunk {
  return (dispatch: Function, getState) => {
    const workspacePhrasesClient = new WorkspacePhraseLocalStorageClient(workspaceId);
    const workspacePhrase = workspacePhrasesClient.loadData();
    const language = getLanguage(getState());

    dispatch(setWorkspacePhraseLanguageAction(workspacePhrase ?? language));
  };
}

export function setWorkspacePhraseLanguage(workspaceId: number, language: string): AppThunk {
  return (dispatch: Function) => {
    const workspacePhrasesClient = new WorkspacePhraseLocalStorageClient(workspaceId);

    workspacePhrasesClient.setData(language);
    dispatch(setWorkspacePhraseLanguageAction(language));
  };
}

export function setVirtualBackgrounds(virtualBackgrounds: VirtualBackgroundType[]) {
  return {
    type: AppActions.SET_VIRTUAL_BACKGROUNDS,
    payload: { virtualBackgrounds },
  } as const;
}

export function addVirtualBackground(virtualBackground: VirtualBackgroundType): AppThunk {
  return (dispatch: Function) => {
    const vbgInLocalStorage: string[] = JSON.parse(localData.fetch("settings.virtualBackgrounds"));

    localData.set("settings.virtualBackgrounds", JSON.stringify([...vbgInLocalStorage, virtualBackground]));
    dispatch(addVirtualBackgroundAction(virtualBackground));
  };
}

function addVirtualBackgroundAction(virtualBackground: VirtualBackgroundType) {
  return {
    type: AppActions.ADD_VIRTUAL_BACKGROUND,
    payload: { virtualBackground },
  } as const;
}

export function removeVirtualBackground(id: string): AppThunk {
  return (dispatch: Function) => {
    const vbgInLocalStorage: VirtualBackgroundType[] = JSON.parse(localData.fetch("settings.virtualBackgrounds"));

    localData.set("settings.virtualBackgrounds", JSON.stringify(vbgInLocalStorage.filter(el => el.id !== id)));
    dispatch(removeVirtualBackgroundAction(id));
  };
}

function removeVirtualBackgroundAction(id: string) {
  return {
    type: AppActions.REMOVE_VIRTUAL_BACKGROUND,
    payload: { id },
  } as const;
}

export function hydrateWindow(): AppThunk {
  return async (dispatch: Function) => {
    if (window.electron) {
      const ipcRenderer = window.electron.ipcRenderer;

      ipcRenderer.on(channels.WINDOW_MAXIMIZED, async (_, { id, maximized }) => {
        if (id === window.windowId) dispatch(setMaximized(maximized));
      });
    }
  };
}

export function hydrateStartup(): AppThunk {
  return (dispatch: Function) => {
    dispatch(setStartup(localData.enabled("settings.app.startup")));
  };
}

export function hydrateNotificationConfig(): AppThunk {
  return (dispatch: Function) => {
    const promoteWebVersionOnMobile = {
      closeCount: parseInt(localData.fetch("notification.promoteWebVersionOnMobile.closeCount")),
    };

    const warnMissingSlackScopes = {
      closeCount: parseInt(localData.fetch("notification.warnMissingSlackScopes.closeCount")),
      missingScopes: readLocalSlackMissingScopes(),
    };

    dispatch(
      updateNotificationConfigAction({
        promoteWebVersionOnMobile,
        warnMissingSlackScopes,
      }),
    );
  };
}

type MissingScopes = AppState["notificationConfig"]["warnMissingSlackScopes"]["missingScopes"];

function readLocalSlackMissingScopes(): MissingScopes {
  const currentLocalValue = localData.fetch("notification.warnMissingSlackScopes.missingScopes");
  const defaultEmptyValue = JSON.stringify({ botScopes: [], userScopes: [] } as MissingScopes);

  const value = JSON.parse(currentLocalValue || defaultEmptyValue);

  // TODO: delete this code for handling old versions after some time
  // Old version (for backward compatibility only)
  if (Array.isArray(value)) {
    const oldMissingScopes = value as string[];

    return {
      botScopes: oldMissingScopes.filter(scope =>
        [
          "im:read",
          "im:write",
          "im:history",
          "channels:read",
          "channels:join",
          "groups:read",
          "chat:write",
          "users.profile:read",
        ].includes(scope),
      ),
      userScopes: oldMissingScopes.filter(scope => ["users.profile:write"].includes(scope)),
    };
  }

  // Current version
  if (
    "botScopes" in value &&
    "userScopes" in value &&
    Array.isArray(value.botScopes) &&
    Array.isArray(value.userScopes)
  ) {
    return value as MissingScopes;
  }

  throw new Error("Unexpected situation");
}

export function loadPermission(permission: keyof AppState["permissions"]): AppThunk {
  return async (dispatch: Function) => {
    let permissionValue = false;

    switch (permission) {
      case "microphone":
        permissionValue = window.electron ? await ipc.checkForPermission("Microphone") : true;
        if (!permissionValue) {
          await dispatch(changeMicStatus(true));
        }

        break;
      case "camera":
        permissionValue = window.electron ? await ipc.checkForPermission("Camera") : true;
        if (!permissionValue) {
          // TODO: turn camera OFF on UI
        }

        break;
      case "screenshare":
        permissionValue = window.electron
          ? await ipc.checkForPermission("ScreenCapture")
          : // TODO: check if on browser there is a way to detect if this permission was granted
            true;
        break;
      case "accessibility":
        permissionValue = window.electron ? await ipc.checkForPermission("Accessibility") : true;
        break;
      case "webNotification":
        permissionValue = window.electron ? true : await requestWebNotificationPermission();
        break;
      default:
        throw new Error("Tried to load an unknown permission");
    }

    await dispatch(setPermissionAction(permission, permissionValue));
  };
}

function updateNotificationConfigAction(payload: Partial<AppState["notificationConfig"]>) {
  return {
    type: AppActions.UPDATE_TOP_BAR_CONFIG,
    payload,
  } as const;
}

export function setPermissionAction(permission: keyof AppState["permissions"], value: boolean) {
  return {
    type: AppActions.SET_PERMISSION,
    payload: { permission, value },
  } as const;
}

export function setAudioEffectAction(audioEffect: AudioEffect | null) {
  return {
    type: AppActions.SET_AUDIO_EFFECT,
    payload: { audioEffect },
  } as const;
}

export function closeNotificationPromoteWebVersionOnMobile(): AppThunk {
  return (dispatch: Function, getState: () => State) => {
    const { notificationConfig } = getApp(getState());
    const { promoteWebVersionOnMobile } = notificationConfig;

    const closeCount = promoteWebVersionOnMobile.closeCount + 1;

    localData.set("notification.promoteWebVersionOnMobile.closeCount", closeCount.toString());

    dispatch(
      updateNotificationConfigAction({
        promoteWebVersionOnMobile: {
          ...promoteWebVersionOnMobile,
          closeCount,
        },
      }),
    );
  };
}

export function setLoading(payload: boolean): SetLoadingAction {
  return { type: AppActions.SET_LOADING, payload };
}

export function setWorkspaceUpdating(payload: boolean): SetWorkspaceUpdatingAction {
  return { type: AppActions.SET_WORKSPACE_UPDATING, payload };
}

export function setStartup(startup: boolean): SetStartupAction {
  return { type: AppActions.SET_STARTUP, payload: { startup } };
}

export function setAppStartUpPreference(startup: boolean): AppThunk {
  return async (dispatch: Function, getState: () => State) => {
    if (window.electron) {
      dispatch(setStartup(startup));
      await setStartUpPreference(startup);

      const savedSetting = await getStartUpPreference();

      localData.set("settings.app.startup", JSON.stringify(startup));
      const savingStartupSettingSuccess = savedSetting === startup ? true : false;

      if (!savingStartupSettingSuccess) {
        Toast.error(
          intl.formatMessage({
            id: "startup-save-fail",
            defaultMessage: "Failed to save startup preferences",
          }),
        );
      } else {
        const me = getMyMember(getState());

        if (me?.workspaceId) {
          dispatch(sendEventAppStartUpPreference(startup, me?.workspaceId));
        }
      }
    }
  };
}

export function loadDesktopAppTitle(): AppThunk {
  return async () => {
    if (window.electron) {
      const config: Config = await sendMessageOverIPC(channels.CONFIG_LOAD);

      document.title = config.productName;
      document.body.classList.add("voiceping", config.productName, "voiceping-theme-dark");
      if (window.electron) document.body.classList.add("voiceping-desktop");
    }
  };
}

export function setUpdatePercent(downloadedPercentage: number): SetUpdatePercentageAction {
  return {
    type: AppActions.SET_UPDATE_PERCENT,
    payload: { downloadedPercentage },
  };
}

export function downloadAppLatestVersion(): AppThunk {
  return () => {
    if (window.electron) {
      downloadApp();
    }
  };
}

function showRequiredMacPermissionModal(data: MacRequiredPermissionModalData): AppThunk {
  return async (dispatch: Function) => {
    await sendMessageOverIPC(channels.SHOW_APP);
    dispatch(showModal({ id: ELECTRON_REQUIRED_MAC_PERMISSION_MODAL_ID, show: true, data }));
  };
}

export function closeRequiredMacPermissionModal(): AppThunk {
  return (dispatch: Function) => {
    dispatch(showModal({ id: ELECTRON_REQUIRED_MAC_PERMISSION_MODAL_ID, show: false }));
  };
}

export function openMacPrivacySystemPreferences(permission: MacPermission): AppThunk {
  return (dispatch: Function) => {
    if (!window.electron) {
      return;
    }

    const uri = `x-apple.systempreferences:com.apple.preference.security?Privacy_${permission}`;

    window.electron.shell.openExternal(uri);
    dispatch(closeRequiredMacPermissionModal());
  };
}

export function showRequiredMicrophonePermissionModal(): AppThunk {
  return (dispatch: Function) => {
    dispatch(
      showRequiredMacPermissionModal({
        permission: "Microphone",
        title: intl.formatMessage({
          id: "microphone-permission-modal/title",
          defaultMessage: "Allow VoicePing to use your microphone",
        }),
        message: intl.formatMessage({
          id: "microphone-permission-modal/message",
          defaultMessage:
            "VoicePing might not have access to your microphone. To fix this problem, please open the system preferences and grant permission.",
        }),
      }),
    );
  };
}

export function showRequiredCameraPermissionModal(): AppThunk {
  return (dispatch: Function) => {
    dispatch(
      showRequiredMacPermissionModal({
        permission: "Camera",
        title: intl.formatMessage({
          id: "camera-permission-modal/title",
          defaultMessage: "Allow VoicePing to use your camera",
        }),
        message: intl.formatMessage({
          id: "camera-permission-modal/message",
          defaultMessage:
            "VoicePing might not have access to your camera. To fix this problem, please open the system preferences and grant permission.",
        }),
      }),
    );
  };
}

export function showRequiredScreenCapturePermissionModal(): AppThunk {
  return (dispatch: Function) => {
    dispatch(
      showRequiredMacPermissionModal({
        permission: "ScreenCapture",
        title: intl.formatMessage({
          id: "screen-permission-modal/title",
          defaultMessage: "Allow VoicePing to share your screen",
        }),
        message: intl.formatMessage({
          id: "screen-permission-modal/message",
          defaultMessage: "In order to screen share, you need to allow VoicePing to access your screen.",
        }),
      }),
    );
  };
}

export function closeApp() {
  sendMessageOverIPC(channels.CONFIRM_CLOSE);
}

export function closeAppWhenSafeAction() {
  return {
    type: AppActions.APP_WAITING_CLOSURE,
  } as const;
}

export function openOnlineLogDiary(): AppThunk {
  return (dispatch: Function, getState: () => State) => {
    const me = getMyMember(getState());

    if (me?.workspaceId) {
      const reportRedirectPath = generateOnlineLogPath(me?.workspaceId);

      openPath(reportRedirectPath);
    }
  };
}

export function openWorkLogWeekly(): AppThunk {
  return (dispatch: Function, getState: () => State) => {
    const me = getMyMember(getState());
    const accountWorkspaceId = getAccountWorkspaceId(getState());

    if (me?.workspaceId) {
      openPath(generateMeetingLogWithParams(me.workspaceId.toString(), TRANSCRIPT_LIBRARY, "recent"));
    } else if (accountWorkspaceId) {
      openPath(generateMeetingLogWithParams(accountWorkspaceId.toString(), TRANSCRIPT_LIBRARY, "recent"));
    }
  };
}

export function slackTokenExpired(payload: { workspaceId: number }): AppThunk {
  return (dispatch: Function, getState: () => State) => {
    const workspace = getCurrentWorkspace(getState());

    if (workspace?.id !== payload.workspaceId) {
      return;
    }

    notification.open({
      type: "warning",
      icon: <WarningAmberIconOutlined />,
      message: <FormattedMessage id="slack-token-expired/message" defaultMessage="Slack token expired" />,
      description: (
        <FormattedMessage
          id="slack-token-expired/description"
          defaultMessage="Your connection with Slack expired, please reconnect."
        />
      ),
      primaryButton: {
        label: <FormattedMessage id="slack-token-expired/reconnect" defaultMessage="Reconnect" />,
        onClick: () => dispatch(connectSlackAccount()),
      },
      duration: 0,
    });
  };
}

export const closeExternalWindows = (): AppThunk => {
  return () => {
    if (window.notificationsWindow && !window.notificationsWindow.closed) {
      window.notificationsWindow.onbeforeunload = null;
      window.notificationsWindow.close();
      window.notificationsWindow = null;
    }

    if (window.stopSharingWindow && !window.stopSharingWindow.closed) {
      window.stopSharingWindow.onbeforeunload = null;
      window.stopSharingWindow.close();
      window.stopSharingWindow = null;
    }

    if (window.stopRemoteControlWindow && !window.stopRemoteControlWindow.closed) {
      window.stopRemoteControlWindow.onbeforeunload = null;
      window.stopRemoteControlWindow.close();
      window.stopRemoteControlWindow = null;
    }

    if (window.screenshareOverlayWindow && !window.screenshareOverlayWindow.closed) {
      window.screenshareOverlayWindow.onbeforeunload = null;
      window.screenshareOverlayWindow.close();
      window.screenshareOverlayWindow = null;
    }

    if (window.textChatWindow && !window.textChatWindow.closed) {
      window.textChatWindow.onbeforeunload = null;
      window.textChatWindow.close();
      window.textChatWindow = null;
    }

    if (window.textChatWindowForAudioExtraction && !window.textChatWindowForAudioExtraction.closed) {
      window.textChatWindowForAudioExtraction.onbeforeunload = null;
      window.textChatWindowForAudioExtraction.close();
      window.textChatWindowForAudioExtraction = null;
    }
  };
};

export function setMaximized(maximized: boolean): SetMaximizedAction {
  return { type: AppActions.SET_MAXIMIZED, payload: { maximized } };
}

function setDuplicatedLoginAction(isDuplicated: boolean) {
  return {
    type: AppActions.SET_DUPLICATED_LOGIN,
    payload: { isDuplicated },
  } as const;
}

export function setDuplicatedLogin(isDuplicated: boolean): AppThunk {
  return (dispatch: Function) => {
    dispatch(setDuplicatedLoginAction(isDuplicated));
    dispatch(showModal({ id: DUPLICATE_LOGIN_MODAL_ID, show: isDuplicated }));
  };
}

export function duplicateLogin(): AppThunk {
  return (dispatch: Function) => {
    console.log("There is duplicated user, disconnecting socket");
    dispatch(setDuplicatedLogin(true));
    // dispatch(disconnectSocket());
  };
}

declare global {
  interface Window {
    notificationsWindow: Window | null;
    stopSharingWindow: Window | null;
    stopRemoteControlWindow: Window | null;
    screenshareOverlayWindow: Window | null;
    textChatWindow: Window | null;
    textChatWindowForAudioExtraction: Window | null;
    loginWindow: Window | null;
    subtitleWindow: Window | null;
  }
}

export const openNotificationsWindow = (): AppThunk => {
  return (dispatch: Function, getState: () => State) => {
    const isExtractingAudio = getIsExtractingAudio(getState());
    const isRecordingAudioExtraction = getIsRecordingAudioExtraction(getState());

    if (isExtractingAudio || isRecordingAudioExtraction) return;

    const lastPosition = getNotificationWindowLastPosition(getState());
    const existingNotifications = checkExistingNotifications(getState());
    const existingUnreadMessages = checkUnreadMessages(getState());
    const checkIfNotificationCanBeShown = canShowNotificationWindow(
      existingNotifications,
      existingUnreadMessages,
    )(getState());
    const socketConnectionStatus = getSocketConnectivityStatus(getState());
    const isChannelLoading = getIsChannelIsLoading(getState());
    const isChannelJoining = getIsChannelJoining(getState());
    const onlineUsers = getOnlineUserIdsOnFloor(getState());

    if (onlineUsers.length === 0) return;

    if (!checkIfNotificationCanBeShown || !socketConnectionStatus || isChannelLoading || isChannelJoining) return;

    let positionX = NOTIFICATION_WINDOW_DEFAULT_POSITION_X;
    let positionY = NOTIFICATION_WINDOW_DEFAULT_POSITION_Y;

    if (lastPosition) {
      positionX =
        (lastPosition.position.x / (lastPosition.screenResolution.width - NOTIFICATION_WINDOW_INITIAL_WIDTH)) *
        (window.screen.width - NOTIFICATION_WINDOW_INITIAL_WIDTH);
      positionY =
        (lastPosition.position.y / (lastPosition.screenResolution.height - NOTIFICATION_WINDOW_INITIAL_HEIGHT)) *
        (window.screen.height - NOTIFICATION_WINDOW_INITIAL_HEIGHT);
    }

    zohoToggleWindow("hide");

    window.notificationsWindow = window.open(NOTIFICATIONS_WINDOW_ROUTE, "notification-window");
    window.notificationsWindow?.moveTo(positionX, positionY);
  };
};

export const openStopSharingWindow = (): AppThunk => {
  return (dispatch: Function, getState: () => State) => {
    const isStopSharingWindowDisabled = getStopSharingWindowOpeningStatus(getState());

    if (isStopSharingWindowDisabled) return;

    const socketConnectionStatus = getSocketConnectivityStatus(getState());
    const isChannelLoading = getIsChannelIsLoading(getState());
    const isChannelJoining = getIsChannelJoining(getState());

    if (!socketConnectionStatus || isChannelLoading || isChannelJoining) return;

    window.stopSharingWindow = window.open(STOP_SHARING_WINDOW_ROUTE, "stop-sharing-window");
    const positionXW = (window.screen.width - 380) / 2;
    const positionYW = window.screen.height - 50;

    window.stopSharingWindow?.moveTo(positionXW, positionYW);
  };
};

export function showTextChatWindow(dmUserId?: number): AppThunk {
  return (dispatch: Function) => {
    if (!window.electron) return;

    dispatch(setFocusChatBoxAction(true));
    dispatch(setDMUserIds(dmUserId ? [dmUserId] : undefined));
    sendMessageOverIPC(channels.SHOW_TEXT_CHAT_WINDOW);
  };
}

export function showSubtitle(forceOpen?: boolean): AppThunk {
  return (dispatch: Function) => {
    if (!window.electron) return;

    dispatch(setIsSubtitleOpened(true));
    dispatch(setOpenTextChatInAnotherWindow(true));

    if (forceOpen) {
      dispatch(setTextChatWindowType(TextChatWindowType.SUBTITLE));
    }

    sendMessageOverIPC(channels.SHOW_SUBTITLE_WINDOW, { forceOpen });
    ipc.closeElectronWindow(PRIMARY_WINDOW_ID, false, false);
  };
}

export function hideSubtitle(): AppThunk {
  return (dispatch: Function) => {
    if (!window.electron) return;

    // sendMessageOverIPC(channels.SHOW_APP);

    dispatch(setIsSubtitleOpened(false));
    dispatch(setOpenTextChatInAnotherWindow(false));

    sendMessageOverIPC(channels.HIDE_SUBTITLE_WINDOW);
  };
}

export const setIsOpeningTextChatWindow = (isOpeningTextChatWindow: boolean) => {
  return {
    type: AppActions.SET_IS_OPEN_TEXT_CHAT_WINDOW,
    payload: { isOpeningTextChatWindow },
  } as const;
};

export const openTextChatWindow = (): AppThunk => {
  return (dispatch: Function, getState: () => State) => {
    const isExtractingAudio = getIsExtractingAudio(getState());
    const isRecordingAudioExtraction = getIsRecordingAudioExtraction(getState());
    const isLoadingRecordAudioExtraction = getIsLoadingRecordAudioExtraction(getState());

    if (!window.electron || isExtractingAudio || isRecordingAudioExtraction || isLoadingRecordAudioExtraction) return;

    dispatch(hideChatBoxInMainWindow());

    window.textChatWindow = window.open(TEXT_CHAT_WINDOW_ROUTE, "text-chat-window");
    dispatch(setIsOpeningTextChatWindow(true));
    dispatch(setOpenTextChatInAnotherWindow(true));

    const lastPoisition = getTextChatWindowLastPosition(getState());

    if (lastPoisition) {
      window.textChatWindow?.moveTo(lastPoisition.position.x, lastPoisition.position.y);
      window.textChatWindow?.resizeTo(lastPoisition.screenResolution.width, lastPoisition.screenResolution.height);
    }
  };
};

export const openSubtitle = (forceOpen?: boolean): AppThunk => {
  return (dispatch: Function, getState: () => State) => {
    if (!window.electron) return;

    const currentTextChatWindowType = getCurrentTextChatWindowType(getState());

    if (currentTextChatWindowType !== TextChatWindowType.SUBTITLE && !forceOpen) return;

    if (forceOpen) {
      dispatch(setTextChatWindowType(TextChatWindowType.SUBTITLE));
    }

    window.subtitleWindow = window.open(SUBTITLE_WINDOW_ROUTE, "subtitle-window");

    const lastPoisition = getTextChatWindowLastPosition(getState());

    if (lastPoisition) {
      window.subtitleWindow?.moveTo(lastPoisition.position.x, lastPoisition.position.y);
      window.subtitleWindow?.resizeTo(lastPoisition.screenResolution.width, lastPoisition.screenResolution.height);
    }
  };
};

export function hideTextChatWindow(): AppThunk {
  return dispatch => {
    sendMessageOverIPC(channels.HIDE_TEXT_CHAT_WINDOW);
    dispatch(setIsOpeningTextChatWindow(false));
  };
}

export function showTextChatWindowForAudioExtraction(forceOpen?: boolean): AppThunk {
  return (dispatch: Function, getState: () => State) => {
    if (forceOpen) {
      dispatch(setTextChatWindowType(TextChatWindowType.TEXT_CHAT_WINDOW));
    }

    sendMessageOverIPC(channels.SHOW_TEXT_CHAT_WINDOW_FOR_AUDIO_EXTRACTION, { forceOpen });
  };
}

export const openTextChatWindowForAudioExtraction = (forceOpen?: boolean): AppThunk => {
  return (dispatch: Function, getState: () => State) => {
    if (!window.electron) return;

    const isExtractingAudio = getIsExtractingAudio(getState());
    const isRecordingAudioExtraction = getIsRecordingAudioExtraction(getState());
    const isLoadingRecordAudioExtraction = getIsLoadingRecordAudioExtraction(getState());
    const lastPoisition = getTextChatWindowLastPosition(getState());
    const textChatWindowType = getCurrentTextChatWindowType(getState());

    if (
      (!isExtractingAudio && !(isRecordingAudioExtraction && !isLoadingRecordAudioExtraction)) ||
      (textChatWindowType === TextChatWindowType.SUBTITLE && !forceOpen)
    )
      return;

    if (forceOpen) {
      dispatch(setTextChatWindowType(TextChatWindowType.TEXT_CHAT_WINDOW));
    }

    dispatch(hideTextChatWindow());

    window.textChatWindowForAudioExtraction = window.open(
      TEXT_CHAT_WINDOW_FOR_AUDIO_EXTRACTION_ROUTE,
      "text-chat-window-for-audio-extraction",
    );
    ipc.closeElectronWindow(PRIMARY_WINDOW_ID, false, false);

    if (lastPoisition) {
      window.textChatWindowForAudioExtraction?.moveTo(lastPoisition.position.x, lastPoisition.position.y);
      window.textChatWindowForAudioExtraction?.resizeTo(
        lastPoisition.screenResolution.width,
        lastPoisition.screenResolution.height,
      );
    }
  };
};

export function hideTextChatWindowForAudioExtraction(): AppThunk {
  return (dispatch, getState) => {
    sendMessageOverIPC(channels.HIDE_TEXT_CHAT_WINDOW_FOR_AUDIO_EXTRACTION);
  };
}

export function destroyTextChatWindowForAudioExtraction(): AppThunk {
  return (dispatch, getState) => {
    sendMessageOverIPC(channels.DESTROY_TEXT_CHAT_WINDOW_FOR_AUDIO_EXTRACTION);
  };
}

export function setTextChatWindowLastPosition(payload: NotificationWindowPosition): AppThunk {
  return (dispatch: Function) => {
    dispatch(setTextChatWindowLastPositionAction(payload));
    localData.set("settings.app.textChatWindow.lastPosition", JSON.stringify(payload));
  };
}

export function setTextChatWindowLastPositionAction(payload: NotificationWindowPosition) {
  return {
    type: AppActions.SET_TEXT_CHAT_WINDOW_LAST_POSITION,
    payload,
  } as const;
}

export function hydrateTextChatWindowLastPosition(): AppThunk {
  return (dispatch: Function) => {
    const lastPoisition = localData.fetch("settings.app.textChatWindow.lastPosition");

    dispatch(setTextChatWindowLastPositionAction(JSON.parse(lastPoisition)));
  };
}

export function setAppFocusStatus(isFocused: boolean) {
  return {
    type: AppActions.SET_APP_FOCUS_STATUS,
    payload: { isFocused },
  } as const;
}

export function setNotificationWindowLastPositionAction(payload: NotificationWindowPosition) {
  return {
    type: AppActions.SET_NOTIFICATION_WINDOW_LAST_POSITION,
    payload,
  } as const;
}

export function setNotificationWindowLastPosition(payload: NotificationWindowPosition): AppThunk {
  return (dispatch: Function) => {
    dispatch(setNotificationWindowLastPositionAction(payload));
    localData.set("settings.app.notificationWindow.lastPosition", JSON.stringify(payload));
  };
}

export function hydrateNotificationWindowLastPosition(): AppThunk {
  return (dispatch: Function) => {
    const lastPoisition = localData.fetch("settings.app.notificationWindow.lastPosition");

    dispatch(setNotificationWindowLastPositionAction(JSON.parse(lastPoisition)));
  };
}

export function playSoundEffect(type: PlaySoundEffectTypes | null): AppThunk {
  return (dispatch: Function, getState: () => State) => {
    const me = getMyMember(getState());
    const soundEffect = getSoundEffectEnbaled(getState());

    if (me && !me.silence && soundEffect) {
      if (type) {
        const src = getAudioSource(type);

        dispatch(setAudioEffectAction({ src: src ?? "", loop: type === PlaySoundEffectTypes.INCOMING_CALL }));
      } else {
        dispatch(setAudioEffectAction(null));
      }
    } else {
      if (!type) {
        dispatch(setAudioEffectAction(null));
      }
    }
  };
}

export function setForceReloadAction(payload: { autoReload: boolean }) {
  return {
    type: AppActions.SET_FORCE_RELOAD,
    payload,
  } as const;
}

export const openStopRemoteControlWindow = (): AppThunk => {
  return (dispatch: Function, getState: () => State) => {
    const socketConnectionStatus = getSocketConnectivityStatus(getState());
    const isChannelLoading = getIsChannelIsLoading(getState());

    if (!socketConnectionStatus || isChannelLoading || isChannelLoading) return;

    window.stopRemoteControlWindow = window.open(STOP_REMOTE_CONTROL_WINDOW, "stop-remote-control-window");
    const positionX = (window.screen.width - 500) / 2;
    const positionY = 20;

    window.stopRemoteControlWindow?.moveTo(positionX, positionY);
  };
};

export function showRequiredAccessiblityPermissionModal(): AppThunk {
  return (dispatch: Function) => {
    dispatch(
      showRequiredMacPermissionModal({
        permission: "Accessibility",
        title: intl.formatMessage({
          id: "accessiblity-permission-modal/title",
          defaultMessage: "Allow VoicePing to control you computer",
        }),
        message: intl.formatMessage({
          id: "accessiblity-permission-modal/message",
          defaultMessage:
            "VoicePing might not have access to control your computer. To fix this problem, please open the system preferences and grant permission.",
        }),
      }),
    );
  };
}

export function selectActiveVirtualBackgroundImage(id: string) {
  return {
    type: AppActions.SET_ACTIVE_VIRTUAL_BACKGROUND_IMAGE,
    payload: { id },
  } as const;
}

function setIsCustomizingOfficeBackgroundAction(payload: { isCustomizing: boolean }) {
  return { type: AppActions.SET_IS_CUSTOMIZING_OFFICE_BACKGROUND, payload } as const;
}

export function setIsCustomizingOfficeBackground(isCustomizing: boolean): AppThunk {
  return (dispatch: Function) => {
    dispatch(setIsCustomizingOfficeBackgroundAction({ isCustomizing }));
  };
}

// export const openScreenshareOverlayWindow = (userId: number): AppThunk => {
export const openScreenshareOverlayWindow = (): AppThunk => {
  return (dispatch: Function, getState: () => State) => {
    const socketConnectionStatus = getSocketConnectivityStatus(getState());
    const isChannelLoading = getIsChannelIsLoading(getState());
    const isChannelJoining = getIsChannelJoining(getState());

    const mySharedScreen = getMySharedScreen(getState());

    if (!socketConnectionStatus || isChannelLoading || isChannelJoining || !mySharedScreen) return;

    //Disallow drawing if entire screen is not shared
    if (!mySharedScreen.id?.includes("screen")) return;

    window.screenshareOverlayWindow = window.open(
      SCREENSHARE_OVERLAY_WINDOW,
      "screenshare-overlay-window",
      mySharedScreen.id || "",
    );
  };
};

export function reloadApp(): AppThunk {
  return async () => {
    if (window.electron) {
      await sendMessageOverIPC(channels.HIDE_SCREENSHARE_OVERLAY_WINDOW);
      await sendMessageOverIPC(channels.RELOAD_APP);
    } else {
      window.location.href = "/dashboard";
    }
  };
}

export function switchInActive(inActive: boolean) {
  return {
    type: AppActions.SWITCH_INACTIVE_10SEC,
    payload: { inActive: inActive },
  };
}

function setSoundEffect(soundEffect: boolean) {
  return {
    type: AppActions.SET_SOUND_EFFECT,
    payload: { soundEffect },
  } as const;
}

export function switchSoundEffect(): AppThunk {
  return (dispatch, getState) => {
    const me = getMyMember(getState());

    if (!me) return;

    const soundEffect = localData.fetch("settings.soundeffect");

    if (soundEffect !== "false" && (soundEffect || soundEffect === "true")) {
      localData.set("settings.soundeffect", JSON.stringify(false));
      dispatch(setSoundEffect(false));
      dispatch(
        sendMessage(USER_EVENT_TYPES.USER_APP_SOUND_EFFECT_PREFERENCE, {
          soundEffect: false,
          workspaceId: me.workspaceId,
        }),
      );
      Toast.success(
        intl.formatMessage({
          id: "settings-profile/sound-effect-disbaled",
          defaultMessage: "Disabled sound effect",
        }),
      );
    } else {
      localData.set("settings.soundeffect", JSON.stringify(true));
      dispatch(setSoundEffect(true));
      dispatch(
        sendMessage(USER_EVENT_TYPES.USER_APP_SOUND_EFFECT_PREFERENCE, {
          soundEffect: true,
          workspaceId: me.workspaceId,
        }),
      );
      Toast.success(
        intl.formatMessage({
          id: "settings-profile/sound-effect-enabled",
          defaultMessage: "Enabled sound effect",
        }),
      );
    }
  };
}

export function hydrateSoundEffect(): AppThunk {
  return (dispatch, getState) => {
    const soundEffect = localData.fetch("settings.soundeffect");

    if (soundEffect !== "false" && (soundEffect || soundEffect === "true")) {
      dispatch(setSoundEffect(true));
    } else {
      dispatch(setSoundEffect(false));
    }
  };
}

function setInitialAccessChannelIdAction(workspaceChanneShortlId: string) {
  return {
    type: AppActions.SET_INITIAL_ACCESS_WORKSPACE_CHANNEL_ID,
    payload: { workspaceChanneShortlId },
  } as const;
}

export function setInitialAccessChannelId(): AppThunk {
  return dispatch => {
    const pathname = window.location.pathname;

    if (pathname.startsWith("/dashboard/")) {
      dispatch(setInitialAccessChannelIdAction(pathname.replace("/dashboard/", "")));
    }
  };
}

export function setSilenceBufferNode(silenceNode: AudioBufferSourceNode) {
  return {
    type: AppActions.SET_SILENCE_BUFFER_NODE,
    payload: { silenceNode },
  } as const;
}

export function hydrateWebCameraStatus(): AppThunk {
  return dispatch => {
    const webCameraStatus: boolean = JSON.parse(localData.fetch("settings.workspace.autoOnWebCamera")) ?? false;

    console.log(`WEB CAMERA STATUS LOG: synced web camera status from localStorage`);

    dispatch(setWebCameraStatus(!!webCameraStatus));
  };
}

function setWebCameraStatus(status: boolean) {
  return {
    type: AppActions.SET_WEB_CAMERA_STATUS,
    payload: { status },
  } as const;
}

export function toggleWebCameraStatus(): AppThunk {
  return (dispatch, getState) => {
    try {
      const me = getMyMember(getState());

      if (!me) return;

      const webCameraStatus: boolean = JSON.parse(localData.fetch("settings.workspace.autoOnWebCamera")) ?? false;

      localData.set("settings.workspace.autoOnWebCamera", JSON.stringify(!webCameraStatus));
      dispatch(setWebCameraStatus(!webCameraStatus));

      console.log(`WEB CAMERA STATUS LOG: feature toggle action called.
        video on/off status: ${me.videoActive}
      `);

      Toast.success(
        intl.formatMessage(
          {
            id: "workspace/webcam-on-status-success",
            defaultMessage: "Web camera Status turned {status} successfully!",
          },
          {
            status: !webCameraStatus ? "ON" : "OFF",
          },
        ),
      );
    } catch (err) {
      Toast.success(
        intl.formatMessage({
          id: "workspace/webcam-on-status-error",
          defaultMessage: "Failed to update Web Camera Status!",
        }),
      );
    }
  };
}

export function hydrateStopSharingWindowOpeningStatus(): AppThunk {
  return dispatch => {
    const stopSharingWindowOpeningStatus: boolean =
      JSON.parse(localData.fetch("settings.workspace.hideStopSharingWindow")) ?? false;

    dispatch(setStopSharingWindowOpeningStatus(!!stopSharingWindowOpeningStatus));
  };
}

function setStopSharingWindowOpeningStatus(status: boolean) {
  return {
    type: AppActions.SET_STOP_SHARING_WINDOW_OPENING_STATUS,
    payload: { status },
  } as const;
}

export function toggleStopSharingWindowOpeningStatus(): AppThunk {
  return (dispatch, getState) => {
    try {
      const me = getMyMember(getState());

      if (!me) return;

      const stopSharingWindowOpeningStatus: boolean =
        JSON.parse(localData.fetch("settings.workspace.hideStopSharingWindow")) ?? false;

      localData.set("settings.workspace.hideStopSharingWindow", JSON.stringify(!stopSharingWindowOpeningStatus));
      dispatch(setStopSharingWindowOpeningStatus(!stopSharingWindowOpeningStatus));

      Toast.success(
        intl.formatMessage(
          {
            id: "workspace/show-stop-sharing-window-success",
            defaultMessage: "{status} Stop Sharing Window",
          },
          {
            status: stopSharingWindowOpeningStatus
              ? intl.formatMessage({
                  id: "event-log/event-type-enabled",
                  defaultMessage: "Enabled",
                })
              : intl.formatMessage({
                  id: "event-log/event-type-disabled",
                  defaultMessage: "Disabled",
                }),
          },
        ),
      );
    } catch (err) {
      Toast.success(
        intl.formatMessage({
          id: "workspace/show-stop-sharing-window-error",
          defaultMessage: "Failed to update stop sharing window status!",
        }),
      );
    }
  };
}

export function hydrateOpenTextChatInAnotherWindow(): AppThunk {
  return dispatch => {
    const openTextChatInAnotherWindow: boolean =
      JSON.parse(localData.fetch("settings.workspace.openTextChatInAnotherWindow")) ?? false;

    dispatch(setOpenTextChatInAnotherWindow(!!openTextChatInAnotherWindow));
  };
}

export function setOpenTextChatInAnotherWindowAction(status: boolean) {
  return {
    type: AppActions.SET_OPEN_TEXT_CHAT_IN_ANOTHER_WINDOW,
    payload: { status },
  } as const;
}

export function setOpenTextChatInAnotherWindow(status: boolean): AppThunk {
  return (dispatch, getState) => {
    if (!window.electron) {
      dispatch(setOpenTextChatInAnotherWindowAction(false));

      return;
    }

    const currentState = getOpenTextChatInAnotherWindow(getState());

    if (currentState === status) return;

    localData.set("settings.workspace.openTextChatInAnotherWindow", JSON.stringify(status));
    dispatch(setOpenTextChatInAnotherWindowAction(status));
  };
}

export const openLoginWindow = (url?: string): AppThunk => {
  return (dispatch: Function, getState: () => State) => {
    if (!window.electron) return;

    const ifreamCredentials = JSON.parse(localData.fetch("ifream.credentials")) as {
      url: string;
      username: string;
      password: string;
    }[];

    const ifreamInfo = ifreamCredentials.find(a => a.url === url);

    if (ifreamInfo) {
      window.electron.ipcRenderer.send(channels.LOGIN, {
        username: ifreamInfo.username,
        password: ifreamInfo.password,
      });

      return;
    }

    if (url) localStorage.setItem("currentIFreamURL", url);

    window.loginWindow = window.open(LOGIN_WINDOW_ROUTE, "login-window");
  };
};

export const storeIFramePassword = (data: { url: string; username: string; password: string }) => {
  const ifreamCredentials = JSON.parse(localData.fetch("ifream.credentials")) as {
    url: string;
    username: string;
    password: string;
  }[];

  const updatedValue = ifreamCredentials.filter(a => a.url !== data.url);

  updatedValue.push(data);
  localData.set("ifream.credentials", JSON.stringify(updatedValue));
};

export const removeIFramePassword = (url: string) => {
  const ifreamCredentials = JSON.parse(localData.fetch("ifream.credentials")) as {
    url: string;
    username: string;
    password: string;
  }[];

  const updatedValue = ifreamCredentials.filter(a => a.url !== url);

  localData.set("ifream.credentials", JSON.stringify(updatedValue));
};

export function hideLoginWindow(): AppThunk {
  return dispatch => {
    sendMessageOverIPC(channels.HIDE_LOGIN_WINDOW);
  };
}

function setIdleFeatureStatus(status: boolean) {
  return {
    type: AppActions.SET_IDLE_FEATURE_STATUS,
    payload: { status },
  } as const;
}

export function hydrateIdleFeatureStatus(): AppThunk {
  return dispatch => {
    const idleFeatureStatus: boolean = JSON.parse(localData.fetch("settings.workspace.idleFeatureStatus")) ?? false;

    dispatch(setIdleFeatureStatus(!!idleFeatureStatus));
  };
}

export function toggleIdleFeatureStatus(isIdleFeatureStatusEnabled: boolean): AppThunk {
  return dispatch => {
    try {
      localData.set("settings.workspace.idleFeatureStatus", JSON.stringify(isIdleFeatureStatusEnabled));

      dispatch(setIdleFeatureStatus(isIdleFeatureStatusEnabled));

      Toast.success(
        intl.formatMessage(
          {
            id: "workspace/idle-feature-update-status-success",
            defaultMessage: "Idle feature {status} successfully!",
          },
          {
            status: isIdleFeatureStatusEnabled
              ? intl.formatMessage({
                  id: "event-log/event-type-enabled",
                  defaultMessage: "Enabled",
                })
              : intl.formatMessage({
                  id: "event-log/event-type-disabled",
                  defaultMessage: "Disabled",
                }),
          },
        ),
      );
    } catch (err) {
      Toast.success(
        intl.formatMessage({
          id: "workspace/idle-feature-update-status-error",
          defaultMessage: "Failed to update idle feature status!",
        }),
      );
    }
  };
}

export function hydratePipDefaultScreenshareWindowStatus(): AppThunk {
  return dispatch => {
    const isPipDefaultScreenshareWindowEnabled: boolean =
      JSON.parse(localData.fetch("settings.workspace.pipDefaultScreenshareWindowStatus")) ?? false;

    dispatch(setPipDefaultScreenshareWindowStatus(!!isPipDefaultScreenshareWindowEnabled));
  };
}

function setPipDefaultScreenshareWindowStatus(status: boolean) {
  return {
    type: AppActions.SET_PIP_DEFAULT_SCREENSHARE_STATUS,
    payload: { status },
  } as const;
}

export function togglePipDefaultScreenShareWindowStatus(): AppThunk {
  return (dispatch, getState) => {
    try {
      const isPipDefaultScreenshareWindowEnabled = getPipDefaultScreenshareWindowStatus(getState());

      localData.set(
        "settings.workspace.pipDefaultScreenshareWindowStatus",
        JSON.stringify(!isPipDefaultScreenshareWindowEnabled),
      );

      dispatch(setPipDefaultScreenshareWindowStatus(!isPipDefaultScreenshareWindowEnabled));

      Toast.success(
        intl.formatMessage(
          {
            id: "workspace/pip-screen-share-default-window-success",
            defaultMessage: "Successfully {status} PIP screen share default window!",
          },
          {
            status: isPipDefaultScreenshareWindowEnabled
              ? intl.formatMessage({
                  id: "event-log/event-type-enabled",
                  defaultMessage: "Enabled",
                })
              : intl.formatMessage({
                  id: "event-log/event-type-disabled",
                  defaultMessage: "Disabled",
                }),
          },
        ),
      );
    } catch (err) {
      Toast.success(
        intl.formatMessage({
          id: "workspace/pip-screen-share-default-window-error",
          defaultMessage: "Failed to update PIP screen share default window!",
        }),
      );
    }
  };
}

export function processWebNotifications(data: any): AppThunk {
  return async (dispatch, getState) => {
    if (!data.type) return;

    const me = getMyMember(getState());

    const { type, payload } = data;
    const currentWorkspace = getCurrentWorkspace(getState());

    if (currentWorkspace && currentWorkspace.id !== payload.workspaceId) {
      const workspace = getAllWorkspaces(getState()).find(a => a.id === payload.workspaceId);

      if (workspace) {
        await joinWorkspaceByShortId(workspace.shortId);
        return;
      }
    }

    if (type === CHANNEL_EVENT_TYPES.CHANNEL_INVITE) {
      localData.set("webPushNotifications", "[]");
      dispatch(
        voiceChannelInviteSendResponse({
          workspaceId: payload.workspaceId,
          voiceChannelId: payload.voiceChannelId,
          invitationId: payload.invitationId,
          option: "join-now",
          invitedByUserId: payload.invitedByUserId,
          invitedByUserPosition: payload.invitedByUserPosition,
          isGetCloserInvite: payload?.isGetCloserInvite,
        }),
      );
    }

    if (type === TEXT_CHANNEL_EVENT_TYPES.NEW_MESSAGE_RESPONSE) {
      const currentChannel = getCurrentVoiceChannel(getState());
      const members = getTextChatAllowMembers(getState());

      const additionalOptions = currentChannel?.parentVoiceChannelId
        ? getTextChatMeetingRoomAdditionalOptions(me?.role === "guest")
        : getTextChatFloorAdditionalOptions(me?.role === "guest");

      localData.set("webPushNotifications", "[]");

      dispatch(showChatBox());
      if (payload.message.receiver) {
        if (additionalOptions.some(a => a.id === payload.message.receiver)) {
          dispatch(setDMUserIds(payload.message.receiver));
        }
      } else {
        let targetUsers = payload.message.users ? payload.message.users.filter((u: any) => u !== me?.id!) : [];

        targetUsers.push(payload.message.userId);

        dispatch(setDMUserIds(targetUsers.filter((a: number) => members.some(m => m.id === a))));
      }
    }
  };
}

function hydrateShowOriginalMessage() {
  return (dispatch: Function) => {
    const showOriginalTextLocalStorageClient = new ShowOriginalTextLocalStorageClient();
    const showOriginalMessage = showOriginalTextLocalStorageClient.loadData();

    if (typeof showOriginalMessage !== "boolean") {
      showOriginalTextLocalStorageClient.setData(true);
    } else {
      dispatch(updateShowOriginalMessage(showOriginalMessage));
    }
  };
}

export const setIsSubtitleOpened = (isSubtitleOpened: boolean) => {
  return {
    type: AppActions.SET_IS_SUBTITLE_OPENED,
    payload: { isSubtitleOpened },
  } as const;
};

export function blurHandlerForTextChat() {
  return (dispatch: Function, getState: () => State) => {
    if (!window.electron) return;

    const isExtractingAudio = getIsExtractingAudio(getState());
    const isRecordingAudioExtraction = getIsRecordingAudioExtraction(getState());
    const isLoadingRecordAudioExtraction = getIsLoadingRecordAudioExtraction(getState());
    const lastPoisition = getTextChatWindowLastPosition(getState());
    const textChatWindowType = getCurrentTextChatWindowType(getState());

    if (!isExtractingAudio && !(isRecordingAudioExtraction && !isLoadingRecordAudioExtraction)) return;

    dispatch(hideTextChatWindow());

    if (textChatWindowType === TextChatWindowType.TEXT_CHAT_WINDOW) {
      window.textChatWindowForAudioExtraction = window.open(
        TEXT_CHAT_WINDOW_FOR_AUDIO_EXTRACTION_ROUTE,
        "text-chat-window-for-audio-extraction",
      );
      ipc.closeElectronWindow(PRIMARY_WINDOW_ID, false, false);

      if (lastPoisition) {
        window.textChatWindowForAudioExtraction?.moveTo(lastPoisition.position.x, lastPoisition.position.y);
        window.textChatWindowForAudioExtraction?.resizeTo(
          lastPoisition.screenResolution.width,
          lastPoisition.screenResolution.height,
        );
      }
    } else if (textChatWindowType === TextChatWindowType.SUBTITLE) {
      window.subtitleWindow = window.open(SUBTITLE_WINDOW_ROUTE, "subtitle-window");
      ipc.closeElectronWindow(PRIMARY_WINDOW_ID, false, false);

      if (lastPoisition) {
        window.subtitleWindow?.moveTo(lastPoisition.position.x, lastPoisition.position.y);
        window.subtitleWindow?.resizeTo(lastPoisition.screenResolution.width, lastPoisition.screenResolution.height);
      }
    }
  };
}
