import dayjs from "dayjs";
import { ChatMessage, MapTransform, Position, PrevLocation } from "./store/types";

const defaults = {
  "settings.app.startup": true,
  "settings.app.notificationWindow.lastPosition": null,
  "settings.app.textChatWindow.lastPosition": null,
  "settings.device.video.input.id": "",
  "settings.device.video.input.label": "",
  "settings.device.video.input.groupId": "",
  "settings.device.video.isVirtualBackgroundOn": false,
  "settings.device.audio.input.id": "",
  "settings.device.audio.input.label": "",
  "settings.device.audio.input.groupId": "",
  "settings.device.audio.input.autoGainStatus": true,
  "settings.device.audio.output.id": "",
  "settings.device.audio.output.label": "",
  "settings.device.audio.output.groupId": "",
  "settings.device.audio.noiseReduction": false,
  "settings.virtualBackgrounds": JSON.stringify([]),
  "settings.device.audio.enableFloorIdleAutoMicMute": false,
  "notification.promoteWebVersionOnMobile.closeCount": "0",
  "notification.warnMissingSlackScopes.closeCount": "0",
  "notification.warnMissingSlackScopes.missingScopes": null,
  "settings.workspace.nativeNotification": true,
  "notification.productUpdate.badge.isChecked": false,
  "settings.workspace.referralToken": null,
  "timeTracker.stopped.userAway": false,
  "timeTracker.status.isWorking": false,
  "sidebar.searchMember.history": JSON.stringify([]),
  "sidebar.voiceChannel.collapsedVoiceChannels": JSON.stringify([]),
  "sidebar.offlineUsers.isOpened.workspaceIds": JSON.stringify([]),
  "memo.notes": "",
  "azure.stt.speechConfig.endpointId": "",
  "settings.activeVBG.id": "",
  "setting.workspace.id": null,
  "settings.camera.resolution": "qvga",
  "settings.soundeffect": true,
  "settings.screen.resolution": "hd",
  openInBrowser: false,
  language: "en",
  "sidebar.voiceChannel.pinnedVoiceChannels": JSON.stringify([]),
  "sidebar.voiceChannel.pinnedUsers": JSON.stringify([]),
  "settings.workspace.autoOnWebCamera": false,
  "settings.workspace.hideStopSharingWindow": true,
  "settings.workspace.useOfAudioExtraction": true,
  "settings.workspace.recordAudioExtraction": false,
  "settings.workspace.openTextChatInAnotherWindow": false,
  "settings.workspace.idleFeatureStatus": false,
  "settings.workspace.pipDefaultScreenshareWindowStatus": false,
  "ifream.credentials": JSON.stringify([]),
  "settings.workspace.autoRecordExternalMeeting": true,
  "settings.workspace.audioExtractionDefaultMicStatus": true,
  speakingLanguageValueFromDeviceSettingPage: "",
  userNameValueFromDeviceSettingPage: "",
  webPushNotifications: JSON.stringify([]),
  allowedWebPushNotification: "",
  isWebPushNotificationChanged: false,
};

type FeatureKey = keyof typeof defaults;

class LocalStorageService {
  _current: Map<string, boolean>;

  constructor(defaults: any) {
    Object.defineProperty(this, "_current", {
      enumerable: false,
      value: new Map(),
    });
    for (const key of Object.keys(defaults)) {
      this._current.set(key, defaults[key]);
      Object.defineProperty(this, key, {
        enumerable: true,
        get: () => this._current.get(key),
        set: (value: string) => this.set(key as FeatureKey, value),
      });
    }
    this.load();
  }

  /** Load values from storage */
  load() {
    for (const key of this._current.keys()) {
      const stored = localStorage.getItem(`localData.${key}`);

      if (stored !== null) {
        this._current.set(key, stored === "true");
      }
    }
  }

  /** Set stored value, does *not* take effect immediately */
  set(key: FeatureKey, value: string) {
    if (!this._current.has(key)) throw new RangeError("Invalid key");

    localStorage.setItem(`localData.${key}`, value);
  }

  /** @deprecated Hydrate new keys from pre-existing, ignore null TODO: Remove this function in April 2021 */
  tempSet(key: FeatureKey, value: string | null) {
    if (!value) {
      return;
    }

    if (!this._current.has(key)) throw new RangeError("Invalid key");

    localStorage.setItem(`localData.${key}`, value);
  }

  /** Remove from storage, revert to the default; does *not* take effect immediately */
  unset(key: FeatureKey) {
    if (!this._current.has(key)) throw new RangeError("Invalid key");

    localStorage.removeItem(`localData.${key}`);
  }

  /** Check if flag is set */
  // (just for happier TS)
  enabled(key: FeatureKey): boolean {
    if (!this._current.has(key)) throw new RangeError("Invalid key");

    return this._current.get(`${key}`)!;
  }

  fetch(key: FeatureKey): string {
    if (!this._current.has(key)) throw new RangeError("Invalid key");

    return localStorage.getItem(`localData.${key}`) ?? (defaults[key] as string);
  }

  /** Dump to json, for debugging */
  toJSON() {
    const o: any = {};

    for (const key of this._current.keys()) o[key] = this._current.get(key);
    return o;
  }

  /** Dump to string, for debugging */
  toString() {
    return JSON.stringify(this);
  }
}

export const localData = new LocalStorageService(defaults);
export default localData;
// expose to devtools
Object.assign(window, { localData: localData });

export class AvatarPositionLocalStorageClient {
  private _localStorageKey: string;

  constructor(id: number) {
    this._localStorageKey = `map.position.${id}`;
  }

  private parseString(str: string | null) {
    if (str !== null) {
      return JSON.parse(str);
    }

    return null;
  }

  setData(position: Position) {
    localStorage.setItem(this._localStorageKey, JSON.stringify(position));
  }

  loadData() {
    return this.parseString(localStorage.getItem(this._localStorageKey));
  }
}

export class MapStatesLocalStorageClient {
  private _localStorageKey: string;

  constructor(id: number) {
    this._localStorageKey = `map.scale.${id}`;
  }

  private parseString(str: string | null) {
    if (str !== null) {
      return JSON.parse(str) as MapTransform;
    }

    return null;
  }

  setData(transform: MapTransform) {
    localStorage.setItem(this._localStorageKey, JSON.stringify(transform));
  }
  loadData() {
    return this.parseString(localStorage.getItem(this._localStorageKey));
  }
}

export class WorkspacePhraseLocalStorageClient {
  private _localStorageKey: string;

  constructor(workspaceId: number) {
    this._localStorageKey = `settings.workspace.phrase.language.${workspaceId}`;
  }

  setData(language: string) {
    localStorage.setItem(this._localStorageKey, language);
  }

  loadData() {
    return localStorage.getItem(this._localStorageKey);
  }
}

export class PrevLocationLocalStorageClient {
  private _localStorageKey: string;

  constructor(workspaceId: number) {
    this._localStorageKey = `map.myPrevLocation.${workspaceId}`;
  }

  private parseString(str: string | null): PrevLocation | null {
    if (str !== null) {
      return JSON.parse(str) as PrevLocation;
    }

    return null;
  }

  setData(location: PrevLocation) {
    localStorage.setItem(this._localStorageKey, JSON.stringify(location));
  }

  loadData(): PrevLocation | null {
    return this.parseString(localStorage.getItem(this._localStorageKey));
  }
}

class LocalStorageTextChatHistoryService {
  addLocalChatHistoryMessage(workspaceId: number, message: ChatMessage, textChannelId: number) {
    const messageDateString = dayjs().format("YYYY-MM-DD");
    const dataKey = `localData.textChatHistory.${workspaceId}_${messageDateString}`;
    const oldHistoryData = localStorage.getItem(dataKey);

    if (oldHistoryData) {
      try {
        const parsedData = JSON.parse(oldHistoryData);
        const historyArray = Array.isArray(parsedData) ? parsedData : [];

        historyArray.push({ ...message, unread: false, textChannelId: textChannelId });
        localStorage.setItem(dataKey, JSON.stringify(historyArray));
      } catch (error) {
        localStorage.setItem(dataKey, JSON.stringify([{ ...message, unread: false, textChannelId: textChannelId }]));
      }
    } else {
      localStorage.setItem(dataKey, JSON.stringify([{ ...message, unread: false, textChannelId: textChannelId }]));
    }
  }

  getLocalChatHistoryMessages(workspaceId: number): ChatMessage[] {
    let result: ChatMessage[] = [];

    for (let i = 0; i < 3; i++) {
      const beforeDateString = dayjs()
        .add(i - 2, "day")
        .format("YYYY-MM-DD");
      const beforeDayMessages = localStorage.getItem(`localData.textChatHistory.${workspaceId}_${beforeDateString}`);

      if (beforeDayMessages) {
        try {
          const parsedData = JSON.parse(beforeDayMessages);

          if (Array.isArray(parsedData)) result = [...result, ...parsedData];
        } catch (error) {
          localStorage.removeItem(`localData.textChatHistory.${workspaceId}_${beforeDateString}`);
        }
      }
    }

    return result;
  }

  clearLocalChatHistory(workspaceId: number, exceptBefore2Days?: boolean) {
    const regExp = new RegExp(`^localData.textChatHistory.${workspaceId}_\\d+$`);
    const dataKeys = Object.keys(localStorage).filter(key => regExp.test(key));

    if (exceptBefore2Days) {
      const todayDateString = dayjs().format("YYYY-MM-DD");
      const before1DayDateString = dayjs().add(-1, "day").format("YYYY-MM-DD");
      const before2DayDateString = dayjs().add(-2, "day").format("YYYY-MM-DD");

      dataKeys
        .filter(
          key => ![todayDateString, before1DayDateString, before2DayDateString].some(dateString => dateString === key),
        )
        .forEach(key => {
          localStorage.removeItem(key);
        });
    } else {
      dataKeys.forEach(key => {
        localStorage.removeItem(key);
      });
    }
  }

  clearLocalChatHistoryForMeeting(workspaceId: number, textChannelId: number) {
    const dataKeys = Object.keys(localStorage).filter(key => key.includes(`localData.textChatHistory.${workspaceId}`));

    dataKeys.forEach(key => {
      const chats = localStorage.getItem(key);

      if (chats) {
        let chatMessages = JSON.parse(chats);

        chatMessages = chatMessages?.filter((chat: any) => chat.textChannelId !== textChannelId);
        localStorage.setItem(key, JSON.stringify(chatMessages));
      }
    });
  }
}

export class PcSidebarLocalStorageClient {
  private _localStorageKey: string;

  constructor() {
    this._localStorageKey = `pcSidebar`;
  }

  setData(collapased: boolean) {
    localStorage.setItem(this._localStorageKey, collapased.toString());
  }

  loadData(): boolean | null {
    const sotredCollapsed = localStorage.getItem(this._localStorageKey);

    if (sotredCollapsed === "true") {
      return true;
    } else if (sotredCollapsed === "false") {
      return false;
    } else {
      return null;
    }
  }
}

export class ShowOriginalTextLocalStorageClient {
  private _localStorageKey: string;

  constructor() {
    this._localStorageKey = `showOriginalTextdata`;
  }

  setData(showOriginalTextdata: boolean) {
    localStorage.setItem(this._localStorageKey, showOriginalTextdata.toString());
  }

  loadData(): boolean | null {
    const showOriginalTextdata = localStorage.getItem(this._localStorageKey);

    if (showOriginalTextdata === "true") {
      return true;
    } else if (showOriginalTextdata === "false") {
      return false;
    } else {
      return null;
    }
  }
}

export const localDataTextChatHistory = new LocalStorageTextChatHistoryService();
