import { NotificationWindowPosition } from "../types";
import { AppActions, AppActionTypes } from "./actions";

export interface VirtualBackgroundType {
  id: string;
  url?: string;
  blob?: string;
}

export interface AudioEffect {
  src: string;
  loop: boolean;
}

export const appInitialState = {
  silenceNode: null as null | AudioBufferSourceNode,
  loading: true as boolean,
  workspaceUpdating: false,
  workspaceUpdated: false,
  notificationConfig: {
    promoteSlackIntegration: {
      closeCount: 0,
    },
    promoteWebVersionOnMobile: {
      closeCount: 0,
    },
    warnMissingSlackScopes: {
      closeCount: 0,
      missingScopes: {
        botScopes: [] as string[],
        userScopes: [] as string[],
      },
    },
  },
  startup: false,
  downloadedPercentage: 0 as number,
  permissions: {
    screenshare: false,
    microphone: false,
    camera: false,
    accessibility: false,
    webNotification: false,
  },
  waitingClosure: false,
  showOfflineUsers: false,
  maximized: false,
  isAppInFocus: true,
  notificationWindowLastPosition: null as NotificationWindowPosition | null,
  isDuplicatedLogin: false,
  autoReload: false,
  virtualBackgrounds: [] as VirtualBackgroundType[],
  activeVBG: "0",
  isCustomizingOfficeBackground: false,
  phraseLanguage: "en",
  audioEffect: null as AudioEffect | null,
  inActive: false,
  soundEffect: true as boolean,
  initialAccessWorkspaceChannelId: null as string | null,
  webCameraStatus: false,
  disableStopSharingWindow: true,
  textChatWindowLastPosition: null as NotificationWindowPosition | null,
  isOpeningTextChatWindow: false,
  openTextChatInAnotherWindow: true,
  isIdleStatusFeatureEnabled: false,
  isPipDefaultScreenshareWindowEnabled: false,
  isSubtitleOpened: false,
};

export type AppState = typeof appInitialState;

export function appReducer(state: AppState = appInitialState, action: AppActionTypes): AppState {
  switch (action.type) {
    case AppActions.SET_AUDIO_EFFECT:
      return { ...state, audioEffect: action.payload.audioEffect };
    case AppActions.SET_WORKSPACE_UPDATING:
      return {
        ...state,
        workspaceUpdating: action.payload as boolean,
        workspaceUpdated: !action.payload as boolean,
      };
    case AppActions.SET_LOADING:
      return {
        ...state,
        loading: action.payload as boolean,
      };
    case AppActions.SET_DUPLICATED_LOGIN:
      return { ...state, isDuplicatedLogin: action.payload.isDuplicated };

    case AppActions.UPDATE_TOP_BAR_CONFIG:
      return {
        ...state,
        notificationConfig: {
          ...state.notificationConfig,
          promoteWebVersionOnMobile:
            action.payload.promoteWebVersionOnMobile ?? state.notificationConfig.promoteWebVersionOnMobile,
          warnMissingSlackScopes:
            action.payload.warnMissingSlackScopes ?? state.notificationConfig.warnMissingSlackScopes,
        },
      };
    case AppActions.SET_PERMISSION:
      return {
        ...state,
        permissions: {
          ...state.permissions,
          [action.payload.permission]: action.payload.value,
        },
      };
    case AppActions.SET_STARTUP:
      return {
        ...state,
        startup: action.payload.startup,
      };
    case AppActions.SET_UPDATE_PERCENT:
      return {
        ...state,
        downloadedPercentage: action.payload.downloadedPercentage,
      };
    case AppActions.APP_WAITING_CLOSURE:
      return {
        ...state,
        waitingClosure: true,
      };
    case AppActions.SET_MAXIMIZED:
      return {
        ...state,
        maximized: action.payload.maximized,
      };
    case AppActions.SET_APP_FOCUS_STATUS:
      return {
        ...state,
        isAppInFocus: action.payload.isFocused,
      };
    case AppActions.SET_ACTIVE_PHRASE_LANGUAGE:
      return {
        ...state,
        phraseLanguage: action.payload.language,
      };
    case AppActions.SET_NOTIFICATION_WINDOW_LAST_POSITION:
      return {
        ...state,
        notificationWindowLastPosition: action.payload,
      };
    case AppActions.SET_FORCE_RELOAD:
      return {
        ...state,
        autoReload: action.payload.autoReload,
      };
    case AppActions.SET_IS_CUSTOMIZING_OFFICE_BACKGROUND:
      return {
        ...state,
        isCustomizingOfficeBackground: action.payload.isCustomizing,
      };
    case AppActions.SET_VIRTUAL_BACKGROUNDS:
      return { ...state, virtualBackgrounds: action.payload.virtualBackgrounds };
    case AppActions.ADD_VIRTUAL_BACKGROUND:
      return { ...state, virtualBackgrounds: [...state.virtualBackgrounds, action.payload.virtualBackground] };
    case AppActions.REMOVE_VIRTUAL_BACKGROUND:
      return { ...state, virtualBackgrounds: state.virtualBackgrounds.filter(el => el.id !== action.payload.id) };
    case AppActions.SET_ACTIVE_VIRTUAL_BACKGROUND_IMAGE:
      return { ...state, activeVBG: action.payload.id };
    case AppActions.SWITCH_INACTIVE_10SEC:
      return { ...state, inActive: action.payload.inActive };
    case AppActions.SET_SOUND_EFFECT:
      return { ...state, soundEffect: action.payload.soundEffect };
    case AppActions.SET_INITIAL_ACCESS_WORKSPACE_CHANNEL_ID:
      return { ...state, initialAccessWorkspaceChannelId: action.payload.workspaceChanneShortlId };
    case AppActions.SET_SILENCE_BUFFER_NODE:
      return { ...state, silenceNode: action.payload.silenceNode };
    case AppActions.SET_WEB_CAMERA_STATUS:
      return { ...state, webCameraStatus: action.payload.status };
    case AppActions.SET_STOP_SHARING_WINDOW_OPENING_STATUS:
      return { ...state, disableStopSharingWindow: action.payload.status };
    case AppActions.SET_TEXT_CHAT_WINDOW_LAST_POSITION:
      return {
        ...state,
        textChatWindowLastPosition: action.payload,
      };
    case AppActions.SET_IS_OPEN_TEXT_CHAT_WINDOW:
      return {
        ...state,
        isOpeningTextChatWindow: action.payload.isOpeningTextChatWindow,
      };
    case AppActions.SET_OPEN_TEXT_CHAT_IN_ANOTHER_WINDOW:
      return {
        ...state,
        openTextChatInAnotherWindow: action.payload.status,
      };
    case AppActions.SET_IDLE_FEATURE_STATUS:
      return {
        ...state,
        isIdleStatusFeatureEnabled: action.payload.status,
      };
    case AppActions.SET_PIP_DEFAULT_SCREENSHARE_STATUS:
      return {
        ...state,
        isPipDefaultScreenshareWindowEnabled: action.payload.status,
      };
    case AppActions.SET_IS_SUBTITLE_OPENED:
      return {
        ...state,
        isSubtitleOpened: action.payload.isSubtitleOpened,
      };
    default:
      return state;
  }
}
